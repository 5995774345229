import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AuthForm() {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [contact, setContact] = useState("");
  const [role, setRole] = useState("");

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setName("");
    setPassword("");
    setMail("");
    setContact("");
    setRole("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const url = isLogin
        ? "https://api.decors.digitaltriumph.co.in/api/admin/login"
        : "https://api.decors.digitaltriumph.co.in/api/admin/register";
      const data = isLogin ? { name, password } : { name, password, mail, contact, role };

      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200 || res.status === 201) {
        // Handle both successful login and registration
        toast.success(isLogin ? "Login successful!" : "Registration successful!");

        sessionStorage.setItem("username", name);
        sessionStorage.setItem("password", password);
        sessionStorage.setItem("token", res.data.token);

        if (!isLogin) {
          sessionStorage.setItem("mail", mail);
          sessionStorage.setItem("contact", contact);
          sessionStorage.setItem("role", role);
        }

        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        // Handle other status codes as errors
        toast.error(`Unexpected status code: ${res.status}`);
      }
    } catch (err) {
      if (err.response) {
        toast.error(
          `Error: ${
            err.response.data.message || (isLogin ? "Login failed" : "Registration failed")
          }`
        );
      } else if (err.request) {
        toast.error("No response received from server. Please try again later.");
      } else {
        toast.error(`Error: ${err.message}`);
      }
    }
  };

  return (
    <CoverLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Name"
                variant="standard"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </MDBox>
            {!isLogin && (
              <>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    label="Email"
                    variant="standard"
                    fullWidth
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Contact"
                    variant="standard"
                    fullWidth
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Role"
                    variant="standard"
                    fullWidth
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </MDBox>
              </>
            )}
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                variant="standard"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                type="submit"
                fullWidth
                style={{ textTransform: "capitalize", fontSize: 15 }}
              >
                {isLogin ? "Log in" : "Register"}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
                <MDTypography
                  component="button"
                  onClick={toggleForm}
                  variant="button"
                  color="blue"
                  fontWeight="medium"
                  style={{ border: "none", background: "none", cursor: "pointer" }}
                >
                  {isLogin ? "Register" : "Sign In"}
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <ToastContainer />
    </CoverLayout>
  );
}

export default AuthForm;
