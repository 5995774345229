import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Googlemap from "../../layouts/settings/Googlemap";

function settings() {
  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Googlemap />
      </DashboardLayout>
    </MDBox>
  );
}

export default settings;
