import React, { useState, useRef, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Stack, TextField, CardActions, CardContent, IconButton } from "@mui/material";
import Addresstable from "../../layouts/address/Addresstable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { API_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";

const formStyle = {
  position: "relative",
  top: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  marginBottom: 4,
};

function Address() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    mail: "",
    pincode: "",
    state: "",
    label: "",
  });
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhonenumber] = useState("");
  const [mail, setEmail] = useState("");
  const [pincode, setpincode] = useState("");
  const [state, setState] = useState("");
  const [label, setLabel] = useState("");
  const token = sessionStorage.getItem("token");
  const [titles, setTitles] = useState([]);
  const [button, setbutton] = useState(true);
  const [selectedCeremonies, setSelectedCeremonies] = useState([]);
  const cancelTokenSource = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const ButtonOpen = () => {
    setFormOpen(true);
    setbutton(false);
  };

  const ButtonClose = () => {
    setFormOpen(false);
    setbutton(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    // Handle form save logic here
    console.log("Form data saved:", formData);
    handleClose();
  };

  const [uploadFiles, setUploadFiles] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (nameToDelete) => {
    setPersonName((names) => names.filter((name) => name !== nameToDelete));
  };

  // single image upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [bannerImage, setBannerimage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setBannerimage(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  //banners tags
  const [ceremoniesClick, setCeremoniesClick] = useState([]);

  const handleCeremoniesClick = (event) => {
    setCeremoniesClick(event.target.value);
  };

  const handleCeremoniesClickDelete = (valueToRemove) => {
    setCeremoniesClick((prevValues) => prevValues.filter((value) => value !== valueToRemove));
  };

  useEffect(() => {
    console.log(ceremoniesClick);
  }, [ceremoniesClick]);

  const handleDeletes = (nameToDelete) => {
    setPersonNames((names) => names.filter((names) => names !== nameToDelete));
  };

  //post method add form
  const FormSubmit = async () => {
    console.log("Add Form");
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("mail", mail);
      formData.append("pincode", pincode);
      formData.append("state", state);
      formData.append("label", label);
      const res = await axios({
        method: "POST",
        url: `${API_URL}addresses/add`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response", res.data);

      if (res.status === 200) {
        toast.success("Add successfully!");
      }
    } catch (err) {
      if (err.response) {
        toast.error(`Error: ${err.response.data.message || "register failed"}`);
        console.error(`Error response status: ${err.response.status}`);
        console.error(`Error response data:`, err.response.data);
      } else if (err.request) {
        toast.error("No response received from server");
        console.error("No response received:", err.request);
      } else {
        toast.error(`Error: ${err.message}`);
        console.error("Error:", err.message);
      }
    }
  };

  // get method
  return (
    <div>
      <MDBox>
        <DashboardLayout>
          <DashboardNavbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            gap={5}
            mb={5}
            style={{ paddingLeft: "11%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "aliceblue", // Default text color
                      background: "blue", // Default background color
                      "&:hover": {
                        background: "blue", // Keep the background color the same on hover
                      },
                    }}
                  >
                    Export
                  </Button>
                </Box>
              </div>
            </LocalizationProvider>
            {button && (
              <Button
                style={{ textTransform: "capitalize", fontSize: 15 }}
                variant="contained"
                color="dark"
                onClick={ButtonOpen}
              >
                + Add Address
              </Button>
            )}
          </Stack>

          {formOpen && (
            <Box sx={formStyle}>
              <Typography variant="h6" component="h2">
                Add Address
              </Typography>
              <CardContent>
                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Name"
                      variant="standard"
                      name="name"
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Phone Number"
                      variant="standard"
                      name="phone"
                      value={phone}
                      type="number"
                      onChange={(e) => setPhonenumber(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Mail"
                      variant="standard"
                      name="mail"
                      type="email"
                      value={mail}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                </Row>

                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      margin="normal"
                      label="Pincode"
                      variant="standard"
                      type="pincode"
                      value={pincode}
                      onChange={(e) => setpincode(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      margin="normal"
                      label="State"
                      variant="standard"
                      type="state"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      margin="normal"
                      label="label"
                      variant="standard"
                      type="label"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      sx={{ flexDirection: "column", width: 325 }}
                    />
                  </Col>
                </Row>
              </CardContent>
              <Row>
                <CardActions style={{ justifyContent: "start", marginLeft: "15px" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "white",
                      backgroundColor: "black",
                    }}
                    variant="contained"
                    onClick={FormSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    onClick={ButtonClose}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Row>
            </Box>
          )}
          <Addresstable />
        </DashboardLayout>
      </MDBox>
    </div>
  );
}

export default Address;
