import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Stack, TextField, CardActions, CardContent, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import Cermonytable from "../../layouts/cermonies_management/Cermonytable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import { AsyncPaginate } from "react-select-async-paginate";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setCategoriesStartDate } from "../../redux/slices/CategoriesSlice";
import { setCategoriesEndDate } from "../../redux/slices/CategoriesSlice";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const formStyle = {
  position: "relative",
  top: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  marginBottom: 4,
};

function Categories() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    title: "",
    decors: "",
    description: "",
    notes: "",
  });
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [decors, setDecors] = useState("");
  const [tags, setTags] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const token = sessionStorage.getItem("token");
  const [titles, setTitles] = useState([]);
  const [button, setbutton] = useState(true);
  const [view, setview] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTags, setSelectedTags] = useState("");
  const ceremonies = useSelector((state) => state.categories.ceremonies);
  const [updateceremonytable, setupdateceremonytable] = useState(false);
  const startDateFormatted = useSelector((state) => state.teamMembers.teamMembersStartDate);
  const endDateFormatted = useSelector((state) => state.teamMembers.teamMembersEndDate);
  //

  const handleformatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpen = () => {
    setFormOpen(true);
    setbutton(false);
    setupdateceremonytable(false);
  };

  const handleClose = () => {
    setFormOpen(false);
    setbutton(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    // Handle form save logic here
    console.log("Form data saved:", formData);
    handleClose();
  };

  // Dropdowntags
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (nameToDelete) => {
    setPersonName((names) => names.filter((name) => name !== nameToDelete));
  };

  //Dropdowndecors

  // Ceremonies Decors
  const [ceremoniesSelect, setCeremoniesSelect] = useState([]);
  const handleCeremoniesSelect = (event) => {
    setCeremoniesSelect(event.target.value);
  };

  const handleCeremoniesSelectDelete = (valueToRemove) => {
    setCeremoniesSelect((prevValues) => prevValues.filter((value) => value !== valueToRemove));
  };

  useEffect(() => {
    console.log(ceremoniesSelect);
  }, [ceremoniesSelect]);

  //Ceremonies tags
  const [ceremoniesClick, setCeremoniesClick] = useState([]);

  const handleCeremoniesClick = (event) => {
    setCeremoniesClick(event.target.value);
  };

  const handleCeremoniesClickDelete = (valueToRemove) => {
    setCeremoniesClick((prevValues) => prevValues.filter((value) => value !== valueToRemove));
  };

  useEffect(() => {
    console.log(ceremoniesClick);
  }, [ceremoniesClick]);
  // post method add form
  const [ceremonyimages, setCeremonyImages] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setCeremonyImages(file);
    }
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  const handleSubmit = async () => {
    console.log("Add Form");
    console.log(ceremoniesSelectdata); // Log the entire ceremoniesSelectdata for debugging

    try {
      const formData = new FormData();
      // Append each field to the FormData object
      formData.append("title", title);

      // Handle multiple selected decors
      if (Array.isArray(ceremoniesSelectdata) && ceremoniesSelectdata.length > 0) {
        ceremoniesSelectdata.forEach((decor) => formData.append("decors", decor.value));
      } else if (ceremoniesSelectdata.value) {
        formData.append("decors", ceremoniesSelectdata.value);
      }

      selectedTags.forEach((tag) => formData.append("tags", tag.value)); // Handle multiple selected tags
      formData.append("description", description);
      formData.append("notes", notes);
      formData.append("images", ceremonyimages);
      // for (let i = 0; i < uploadFiles.length; i++) {
      //   formData.append("images", uploadFiles[i]);
      // }

      const res = await axios({
        method: "POST",
        url: `${API_URL}ceremony`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setupdateceremonytable(true);
      setbutton(true);
      setFormOpen(false);
      // Handle the response
      console.log("response", res.data);

      if (res.status === 200) {
        toast.success("Add successfully!");
      }
    } catch (err) {
      if (err.response) {
        toast.error(`Error: ${err.response.data.message || "register failed"}`);
        console.error(`Error response status: ${err.response.status}`);
        console.error(`Error response data:`, err.response.data);
      } else if (err.request) {
        toast.error("No response received from server");
        console.error("No response received:", err.request);
      } else {
        toast.error(`Error: ${err.message}`);
        console.error("Error:", err.message);
      }
    }
  };

  // get method

  const [ceremoniesSelectdata, setCeremoniesSelectData] = useState([]);

  const loadBanners = async (search, loadedOptions, { page }) => {
    try {
      const response = await axios.get(`${API_URL}decors`);
      const data = response.data.data;

      const options = data.map((decor) => ({
        value: decor._id,
        label: decor.title,
      }));

      return {
        options,
        hasMore: false, // Change this if your API supports pagination
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading banners:", error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }
  };

  const ceremonySelectData = (selectedOptions) => {
    setCeremoniesSelectData(selectedOptions);
  };

  // Handle tag selection change
  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions || []);
  };

  const asyncPaginateSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "2px",
      boxShadow: "none",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      borderRadius: "16px",
      padding: "2px 8px",
      margin: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#aaa",
      cursor: "pointer",
      ":hover": {
        color: "#333",
      },
    }),
    control: (base) => ({
      ...base,
      variant: "standard",
      border: "none",
      borderBottom: "1px solid #ccc",
      boxShadow: "none",
    }),
    container: (base) => ({
      ...base,
      marginBottom: "20px",
    }),
  };

  // Tags

  const fetchTagsOptions = async (searchQuery, loadedOptions, additional = { page: 1 }) => {
    const { page } = additional;
    const limit = 10; // Define the limit of items per page
    const skip = (page - 1) * limit; // Calculate the skip value based on the current page

    const responseJSON = [
      { title: "Trending 1" },
      { title: "Trending 2" },
      { title: "Trending 3" },
      { title: "Trending 4" },
      { title: "Trending 5" },
      { title: "Naming 1" },
      { title: "Naming 2" },
      { title: "Naming 3" },
      { title: "Naming 4" },
      { title: "Naming 5" },
      { title: "Wedding 1" },
      { title: "Wedding 2" },
      { title: "Wedding 3" },
      { title: "Wedding 4" },
      { title: "Wedding 5" },
    ];

    return {
      options: responseJSON.map((tag) => ({
        value: tag.title,
        label: tag.title,
      })),
      hasMore: false, // Since this is static data, there are no more pages
      additional: {
        page,
      },
    };
  };
  const ceremoniesStartDateChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-GB") : null;
    console.log(formattedDate, "formattedDate");
    setStartDate(date); // Keep the original Date object in state
    // dispatch(setTeamMembersStartDate(date)); // Keep the original Date object in state
    dispatch(setCategoriesStartDate(formattedDate)); // Store formatted string in state
    dispatch(setCategoriesEndDate(null)); // Reset the end date when the start date changes
  };
  const ceremoniesEndDateChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-GB") : null;
    console.log(formattedDate, "formattedDate");
    setEndDate(date); // Keep the original Date object in state
    // dispatch(setTeamMembersEndDate(date)); // Keep the original Date object in state
    dispatch(setCategoriesEndDate(formattedDate)); // Store formatted string in state
  };

  const handleExportClick = () => {
    console.log("Export click initiated", ceremonies);

    if (ceremonies && ceremonies.length > 0) {
      try {
        const dataToExport = ceremonies.map((row) => ({
          Title: row.title || "N/A",
          Decors: row.decors ? row.decors.map((decor) => decor.title).join(", ") : "N/A",
          // Tags: row.tags || "N/A",
          Tags: Array.isArray(row.tags) ? row.tags.join(", ") : row.tags || "N/A", // Handle tags array
          Description: row.description || "N/A",
          Notes: row.notes || "N/A",
          Upload: row.images ? `${IMAGEAPI_URL}${row.images}` : "N/A",
          CreatedOn: row.createdOn ? handleformatDate(row.createdOn) : "N/A",
        }));

        console.log("Data to export:", dataToExport);

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Ceremonies Data");

        XLSX.writeFile(workbook, "CeremoniesData.xlsx");
      } catch (error) {
        console.error("Failed to export data to Excel:", error);
      }
    } else {
      console.warn("No data available for export.");
    }
  };

  // const [ceremonies, setCeremonies] = useState([]);

  useEffect(() => {
    console.log("ceremonies", ceremonies);
  }, [ceremonies]);
  useEffect(() => {
    console.log("endDate", endDateFormatted);
  }, [endDateFormatted]);

  return (
    <div>
      <MDBox>
        <DashboardLayout>
          <DashboardNavbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            mb={5}
            gap={5}
            style={{ paddingLeft: "11%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate} // Use the Date object for value
                    onChange={ceremoniesStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={startDateFormatted || ""} // Use the formatted date for display
                      />
                    )}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate} // Use the Date object for value
                    onChange={ceremoniesEndDateChange}
                    minDate={startDate ? new Date(startDate.getTime() + 86400000) : null} // + 1 day
                    disabled={!startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={endDateFormatted || ""} // Use the formatted date for display
                      />
                    )}
                  />
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "aliceblue", // Default text color
                      background: "blue", // Default background color
                      "&:hover": {
                        background: "blue", // Keep the background color the same on hover
                      },
                    }}
                    onClick={handleExportClick}
                  >
                    Export
                  </Button>
                </Box>
              </div>
            </LocalizationProvider>
            {button && (
              <Button
                style={{ textTransform: "capitalize", fontSize: 15 }}
                variant="contained"
                color="dark"
                onClick={handleOpen}
              >
                + Add Ceremony
              </Button>
            )}
          </Stack>

          {formOpen && (
            <Box sx={formStyle}>
              <Typography variant="h6" component="h2">
                Add Ceremony
              </Typography>
              <CardContent>
                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Title"
                      variant="standard"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                  <Col style={{ width: "200px", flex: 1, marginTop: 25, paddingRight: "45px" }}>
                    <AsyncPaginate
                      isMulti
                      value={ceremoniesSelectdata}
                      loadOptions={loadBanners}
                      onChange={ceremonySelectData}
                      additional={{
                        page: 1,
                      }}
                      placeholder="Select Decors"
                      styles={asyncPaginateSelectStyles}
                      className="decor-asyncPaginate"
                    />
                  </Col>
                  <Col style={{ width: "200px", flex: 1, marginTop: 25 }}>
                    <AsyncPaginate
                      isMulti
                      value={selectedTags}
                      loadOptions={fetchTagsOptions}
                      onChange={handleTagsChange}
                      additional={{
                        page: 1,
                      }}
                      placeholder="Select tags"
                      styles={asyncPaginateSelectStyles}
                      className="decor-asyncPaginate"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Description"
                      variant="standard"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                      multiline
                      rows={2}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Notes"
                      variant="standard"
                      name="notes"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                      multiline
                      rows={2}
                    />
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        backgroundColor: "white",
                        marginTop: 25,
                      }}
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCamera />}
                    >
                      Upload Decors
                      <input
                        type="file"
                        hidden
                        name="images"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: "30px" }}>
                    {selectedFile && (
                      <div style={{ position: "relative", width: "300px" }}>
                        <img
                          src={selectedFile}
                          alt="Selected"
                          style={{ width: 300, height: "auto" }}
                        />
                        <IconButton
                          style={{
                            right: 0,
                            position: "absolute",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: 0, // Adjust padding to make the square smaller if needed
                          }}
                          size="small"
                          onClick={handleRemoveImage}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2.5rem",
                      marginTop: "20px",
                    }}
                  >
                    {selectedFiles.map((file, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={file.preview}
                          alt="Uploaded"
                          style={{ width: "200px", height: "200px", objectFit: "cover" }}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            borderRadius: "5px 0px 0px 5px",
                            backgroundColor: "seagreen",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "auto",
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </Row>
              </CardContent>
              <Row>
                <CardActions style={{ justifyContent: "start", marginLeft: "15px" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "white",
                      backgroundColor: "black",
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    onClick={handleClose}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Row>
            </Box>
          )}
          <div>
            <Cermonytable updateceremonytable={updateceremonytable} />
          </div>
        </DashboardLayout>
      </MDBox>
    </div>
  );
}

export default Categories;
