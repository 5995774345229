import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";
import showConfirmationDialog from "../../utils/sweetalert";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "60%",
};

export default function CustomPaginationActionsTable(updatetable) {
  const [open, setOpen] = useState(false);
  const [enlargedImageSrc, setEnlargedImageSrc] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({
    decors: "",
    upload: "",
  });
  const [editData, setEditData] = useState({
    decors: "",
    upload: "",
  });
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get method
  const [tableData, setTableData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/banners`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data);
      setTableData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // delete method
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const DeleteBannerimage = async (e, id) => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${API_URL}banner/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Banner deleted successfully.");
        setTableData(tableData.filter((tableData) => tableData._id !== id)); // Assuming 'id' is the correct identifier
        setLoading(false);
        toast.success("Banner deleted successfully!"); // Ensure this is called correctly
      } catch (error) {
        console.error("Error deleting ceremony:", error); // Optional: Show an error toast
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const bannerImageClick = (src) => {
    console.log("Image clicked:", src); // Debugging line
    setEnlargedImageSrc(src);
    setOpen(true);
  };

  const CloseBannerimage = () => {
    console.log("Modal closed"); // Debugging line
    setOpen(false);
    setEnlargedImageSrc("");
  };

  //
  //formate date
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: 0,
    marginTop: 10,
  };

  useEffect(() => {
    if (updatetable) {
      fetchData();
    }
  }, [updatetable]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Decors</TableCell>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Images</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {tableData &&
            tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{ width: 20 }}></TableCell>
                <TableCell
                  sx={{ width: 40, fontFamily: '"Roboto"', fontWeight: "450" }}
                ></TableCell>
                <TableCell sx={{ width: 20 }}></TableCell>
                <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {row.decorId?.title}
                </TableCell>
                <TableCell
                  sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                ></TableCell>
                <div>
                  <TableCell>
                    <img
                      src={`${IMAGEAPI_URL}${row.bannerImages}`}
                      alt="Banner"
                      style={{ maxWidth: "40%", cursor: "pointer", borderRadius: "5px" }}
                      onClick={() => bannerImageClick(`${IMAGEAPI_URL}${row.bannerImages}`)}
                    />
                  </TableCell>

                  <Modal
                    open={open}
                    onClose={CloseBannerimage}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      style: { background: "rgba(0, 0, 0, 0.5)" },
                    }}
                  >
                    <Box sx={style} onClick={CloseBannerimage}>
                      <img
                        src={enlargedImageSrc}
                        alt="Enlarged Banner"
                        style={{
                          maxWidth: "50%",
                          maxHeight: "50%",
                          objectFit: "contain",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Box>
                  </Modal>
                </div>
                <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {handleformatDate(row.createdOn)}
                </TableCell>
                <TableCell
                  sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                ></TableCell>
                <TableCell
                  sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                ></TableCell>
                <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  <IconButton aria-label="action" onClick={(e) => DeleteBannerimage(e, row._id)}>
                    <DeleteIcon sx={{ mr: 1 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              colSpan={9}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Modal>
            <Box sx={style}>
              <TextField
                fullWidth
                margin="normal"
                label="Tags"
                variant="standard"
                name="tags"
                value={editData.tags}
                // onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Upload"
                variant="standard"
                name="upload"
                value={editData.upload}
                // onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 8,
                  colSpan: 4,
                  marginRight: 4,
                }}
              >
                <Button
                  style={{ textTransform: "capitalize", color: "white", backgroundColor: "black" }}
                  //   onClick={handleEditCeremony}
                >
                  Update
                </Button>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "black",
                    backgroundColor: "white",
                  }}
                  variant="outlined"
                  //   onClick={handleCloseDialog}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
