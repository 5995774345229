import { configureStore } from "@reduxjs/toolkit";
import TeamMembersSlice from "./slices/TeamMembersSlice";
import CategoriesSlice from "./slices/CategoriesSlice";

export const store = configureStore({
  reducer: {
    teamMembers: TeamMembersSlice,
    categories: CategoriesSlice,
  },
});
