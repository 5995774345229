import React, { useState, useRef, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Stack, TextField, CardActions, CardContent, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import Bannertable from "../../layouts/banners/Bannertable";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { AsyncPaginate } from "react-select-async-paginate";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
const formStyle = {
  position: "relative",
  top: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  marginBottom: 4,
};

function Bannerform() {
  // const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    phoneNumber: "",
  });
  const [bannerFormOpen, setBannerFormOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  const [button, setbutton] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updatetable, setupdatetable] = useState(false);

  const asyncPaginateSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "2px",
      boxShadow: "none",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      borderRadius: "16px",
      padding: "2px 8px",
      margin: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#aaa",
      cursor: "pointer",
      ":hover": {
        color: "#333",
      },
    }),
    control: (base) => ({
      ...base,
      variant: "standard",
      border: "none",
      borderBottom: "1px solid #ccc",
      boxShadow: "none",
    }),
    container: (base) => ({
      ...base,
      marginBottom: "20px",
    }),
  };
  const handleOpen = () => {
    setBannerFormOpen(true);
    setbutton(false);
    setupdatetable(false);
  };

  const handleClose = () => {
    setBannerFormOpen(false);
    setbutton(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    // Handle form save logic here
    console.log("Form data saved:", formData);
    handleClose();
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // single image upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [bannerImage, setBannerimage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setBannerimage(file);
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  //post method add form

  const SaveBannerImage = async () => {
    try {
      const formData = new FormData();
      formData.append("decorId", bannersSelectData.value);
      formData.append("bannerImages", bannerImage);

      const res = await axios.post(`${API_URL}banners/add`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      setupdatetable(true);
      setbannersSelectData([]);
      setSelectedFile(null);
      setBannerimage(null);
      setbutton(true);
      setBannerFormOpen(false);
      if (res.status === 201) {
        toast.success("Added successfully!");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error message:", error.message);
      }

      toast.error("Addition failed");
    }
  };

  // get method
  const [bannersSelectData, setbannersSelectData] = useState([]);

  const loadBanners = async (search, loadedOptions, { page }) => {
    const response = await axios.get(`${API_URL}decors`);

    const data = response.data.data;

    const options = data.map((decor) => ({
      value: decor._id,
      label: decor.title,
    }));

    return {
      options,
      hasMore: false, // Change this if your API supports pagination
      additional: {
        page: page + 1,
      },
    };
  };

  const decorSelectData = (selectedOptions) => {
    setbannersSelectData(selectedOptions);
  };

  return (
    <div>
      <MDBox>
        <DashboardLayout>
          <DashboardNavbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            gap={5}
            mb={5}
            style={{ paddingLeft: "11%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "aliceblue", // Default text color
                      background: "blue", // Default background color
                      "&:hover": {
                        background: "blue", // Keep the background color the same on hover
                      },
                    }}
                  >
                    Export
                  </Button>
                </Box>
              </div>
            </LocalizationProvider>
            {button && (
              <Button
                style={{ textTransform: "capitalize", fontSize: 15 }}
                variant="contained"
                color="dark"
                onClick={handleOpen}
              >
                + Add Banner
              </Button>
            )}
          </Stack>

          {bannerFormOpen && (
            <Box sx={formStyle}>
              <Typography variant="h6" component="h2">
                Add Banner
              </Typography>
              <CardContent>
                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ width: "400px" }}>
                    <AsyncPaginate
                      value={bannersSelectData}
                      loadOptions={loadBanners}
                      onChange={decorSelectData}
                      additional={{
                        page: 1,
                      }}
                      placeholder="Select Decors"
                      styles={asyncPaginateSelectStyles}
                      className="decor-asyncPaginate"
                    />
                  </Col>
                  <Col style={{ flex: 1, marginLeft: "150px" }}>
                    <div>
                      <Button
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "white",
                          marginLeft: 35,
                        }}
                        variant="contained"
                        component="label"
                        startIcon={<PhotoCamera />}
                      >
                        Upload Banner
                        <input
                          type="file"
                          hidden
                          name="image"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: "30px" }}>
                    {selectedFile && (
                      <div style={{ position: "relative", width: "300px" }}>
                        <img
                          src={selectedFile}
                          alt="Selected"
                          style={{ width: 300, height: "auto" }}
                        />
                        <IconButton
                          style={{
                            right: 0,
                            position: "absolute",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: 0, // Adjust padding to make the square smaller if needed
                          }}
                          size="small"
                          onClick={handleRemoveImage}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2.5rem",
                      marginTop: "20px",
                    }}
                  >
                    {selectedFiles.map((file, index) => (
                      <div key={index} style={{ position: "relative" }}>
                        <img
                          src={file.preview}
                          alt="Uploaded"
                          style={{ width: "200px", height: "200px", objectFit: "cover" }}
                        />
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            borderRadius: "5px 0px 0px 5px",
                            backgroundColor: "seagreen",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minWidth: "auto",
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </Row>
              </CardContent>
              <Row>
                <CardActions style={{ justifyContent: "start", marginLeft: "15px" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "white",
                      backgroundColor: "black",
                    }}
                    variant="contained"
                    onClick={SaveBannerImage}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    onClick={handleClose}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Row>
            </Box>
          )}

          <div>
            <Bannertable updatetable={updatetable} />
          </div>
        </DashboardLayout>
      </MDBox>
    </div>
  );
}

export default Bannerform;
