import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function index() {
  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
      </DashboardLayout>
    </MDBox>
  );
}

export default index;
