import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import showConfirmationDialog from "../../utils/sweetalert";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "60%",
};

export default function CustomPaginationActionsTable(updatetable) {
  const [ceremonies, setCeremonies] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [enlargedImageSrc, setEnlargedImageSrc] = useState("");
  const [selectedview, setSelectedView] = useState(null);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    notes: "",
    decors: "",
    tags: "",
    upload: "",
    createdOn: "",
    updatedOn: "",
  });
  const [formData, setFormData] = useState({
    profile: "",
    name: "",
    mail: "",
    contact: "",
    role: "",
    updatedOn: "",
    createdOn: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  const [editorview, setEditorView] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ceremonyImageClick = (src) => {
    console.log("Image clicked:", src); // Debugging line
    setEnlargedImageSrc(src);
    // setOpen(true);
  };
  const CloseCeremonyimage = () => {
    console.log("Modal closed"); // Debugging line
    // setOpen(false);
    setEnlargedImageSrc("");
  };

  //formate date
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return ""; // Return an empty string or a default value if the timestamp is invalid
    }
    const date = new Date(Number(timestamp)); // Ensure timestamp is a number
    return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  // Get method
  // const getData = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}ceremonies`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     console.log(response.data.data);
  //     setCeremonies(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenView = (row, data) => {
    setEditorView(data);
    setEditData({
      title: row.title,
      description: row.description,
      notes: row.notes,
      decors: row.decors.map((decor) => decor.title).join(", "),
      tags: row.tags,
      upload: row.upload,
      createdOn: row.createdOn,
      updatedOn: row.updatedOn,
    });
    setSelectedId(row._id);
    setModelOpen(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setModelOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("event target: " + event.target);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleEditCeremony = async () => {
  //   try {
  //     const response = await axios.put(`${API_URL}ceremony/${selectedId}`, editData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     console.log("Ceremony updated successfully:", response.data);

  //     // Update the ceremonies state
  //     setCeremonies((prevCeremonies) =>
  //       prevCeremonies.map((ceremony) =>
  //         ceremony.id === selectedId ? response.data.data : ceremony
  //       )
  //     );
  //     setLoading(false);
  //     handleCloseMenu();
  //     toast.success("Ceremony deleted successfully!");
  //   } catch (error) {
  //     console.error("Error updating ceremony:", error);
  //     setLoading(false);
  //   }
  // };
  // delete method
  // const handleDeleteCeremony = async () => {
  //   handleCloseMenu();
  //   const isConfirmed = await showConfirmationDialog();
  //   if (isConfirmed) {
  //     try {
  //       setLoading(true);
  //       await axios.delete(`${API_URL}ceremony/${selectedId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log("Ceremony deleted successfully");
  //       setCeremonies(ceremonies.filter((ceremony) => ceremony._id !== selectedId));
  //       setLoading(false);
  //       handleCloseMenu();
  //       toast.success("Ceremony deleted successfully!");
  //     } catch (error) {
  //       console.error("Error deleting ceremony:", error);
  //       setLoading(false);
  //     }
  //   } else {
  //     console.log("Deletion canceled.");
  //   }
  // };

  // view
  useEffect(() => {
    if (updatetable) {
      // getData();
    }
  }, [updatetable]);

  useEffect(() => {
    console.log("ceremonies", ceremonies);
  }, [ceremonies]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Message</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {console.log("hfchggv ceremonies", ceremonies)}
          {/* {ceremonies &&
            ceremonies.map((row, index) => ( */}
          {ceremonies?.map((row) => (
            <TableRow key={row._id}>
              <TableCell
                component="th"
                scope="row"
                sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}
              >
                {/* {row.title} */}
              </TableCell>
              <TableCell sx={{ width: 450, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {/* {row.tags} */}
              </TableCell>
              <TableCell sx={{ width: 0, fontFamily: '"Roboto"', fontWeight: "450" }}>
                <IconButton aria-label="action" onClick={(e) => handleClick(e, row._id)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedId === row._id}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "center", horizontal: "right" }}
                  transformOrigin={{ vertical: "center", horizontal: "left" }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                        fontFamily: "Roboto",
                        fontWeight: "450",
                      },
                    },
                  }}
                >
                  <MenuItem>
                    <VisibilityIcon sx={{ mr: 1 }} />
                    View
                  </MenuItem>
                  <MenuItem>
                    <EditIcon sx={{ mr: 1 }} />
                    Edit
                  </MenuItem>

                  <MenuItem sx={{ color: "error.main" }}>
                    <DeleteIcon sx={{ mr: 1 }} />
                    Delete
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              colSpan={9}
              // count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Modal open={modelOpen} onClose={handleCloseDialog}>
            <Box sx={style}>
              {editorview === "View" && (
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDialog}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <TextField
                fullWidth
                margin="normal"
                label="Title"
                variant="standard"
                name="title"
                value={editData.title}
                onChange={handleInputChange}
                sx={{ flexDirection: "column", gap: 2, width: 230 }}
                style={{ margin: 25 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                variant="standard"
                name="description"
                value={editData.description}
                onChange={handleInputChange}
                sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Notes"
                variant="standard"
                name="notes"
                value={editData.notes}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Decors"
                variant="standard"
                name="decors"
                value={editData.decors}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Tags"
                variant="standard"
                name="tags"
                value={editData.tags}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              {editorview === "View" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="CreatedOn"
                    variant="standard"
                    name="createdOn"
                    value={formatDate(editData.createdOn)}
                    onChange={handleInputChange}
                    sx={{
                      marginLeft: 3,
                      marginTop: 1,
                      flexDirection: "column",
                      gap: 2,
                      width: 230,
                    }}
                    disabled
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="UpdatedOn"
                    variant="standard"
                    name="updatedOn"
                    value={formatDate(editData.updatedOn)}
                    onChange={handleInputChange}
                    sx={{
                      marginLeft: 3,
                      marginTop: 1,
                      flexDirection: "column",
                      gap: 2,
                      width: 230,
                    }}
                    disabled
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                  />
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 8,
                  colSpan: 4,
                  marginRight: 4,
                }}
              >
                {editorview !== "View" && (
                  <>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "black",
                      }}
                      // onClick={handleEditCeremony}
                    >
                      Update
                    </Button>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        fontSize: 15,
                        color: "black",
                        backgroundColor: "white",
                      }}
                      variant="outlined"
                      onClick={handleCloseDialog}
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
