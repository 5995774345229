import React, { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Box,
  Button,
  Card,
  Stack,
  CardContent,
  TextField,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { PhotoCamera, VideoFile } from "@mui/icons-material";
import { Col, Row } from "react-bootstrap";
import Decorstable from "../../layouts/decors/Decorstable";
import axios from "axios";
import { API_URL } from "utils/url";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function Decors() {
  const [showForm, setShowForm] = useState(false);
  // Form variables

  const asyncPaginateSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "2px",
      boxShadow: "none",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      borderRadius: "16px",
      padding: "2px 8px",
      margin: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#aaa",
      cursor: "pointer",
      ":hover": {
        color: "#333",
      },
    }),
    control: (base) => ({
      ...base,
      variant: "standard",
      border: "none",
      borderBottom: "1px solid #ccc",
      boxShadow: "none",
    }),
    container: (base) => ({
      ...base,
      marginBottom: "20px",
    }),
  };

  // post method
  const [title, setTitle] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const [button, setbutton] = useState(true);
  const [budget, setBudget] = useState("");
  const [decorItems, setDecorItems] = useState("");
  const [selectedDecors, setSelectedCeremonies] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const token = sessionStorage.getItem("token");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [updatetable, setupdatetable] = useState(false);
  //
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("budget", budget);

    if (Array.isArray(selectedDecors) && selectedDecors.length > 0) {
      selectedDecors.forEach((ceremony) => formData.append("ceremonies", ceremony.value));
    } else if (selectedDecors.value) {
      formData.append("ceremonies", selectedDecors.value);
    }

    formData.append("selectedTags", JSON.stringify(selectedTags));
    formData.append("description", description);
    formData.append("notes", notes);

    selectedImages.forEach((image) => {
      formData.append("images", image.file);
    });

    selectedVideos.forEach((video) => {
      formData.append("videos", video.file);
    });

    // Add decor items to formData
    const decorItems = items.map((item) => ({
      materialName: item.materialName,
      quantity: item.quantity,
    }));
    formData.append("decorItems", JSON.stringify(decorItems));

    try {
      const response = await axios.post(`${API_URL}decor`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setupdatetable(true);
      setbutton(true);
      setFormOpen(false);
      toast.success("Decor Added Successfully!");
      onFormSubmit(response.data);
    } catch (error) {
      console.error("There was an error posting the decor data!", error);
      toast.error("Error adding decor");
    }
  };

  // loading ceremonies
  const fetchCeremonies = async (
    searchQuery,
    loadedOptions,
    additional = { page: 1 },
    cancelToken
  ) => {
    const { page } = additional;
    const limit = 10; // Define the limit of items per page
    const skip = (page - 1) * limit; // Calculate the skip value based on the current page

    try {
      const response = await axios.get(`${API_URL}ceremonies`, {
        params: {
          skip: skip,
          limit: limit,
          search: searchQuery, // Pass the search query to the API
        },
        cancelToken,
      });

      console.log("res", response);
      const responseJSON = response.data.data;

      return {
        options: responseJSON.map((ceremonies) => ({
          value: ceremonies._id,
          label: ceremonies.title,
        })),
        hasMore: responseJSON.length >= limit,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error fetching options:", error);
      }
      return {
        options: [],
        hasMore: false,
        additional: {
          page,
        },
      };
    }
  };

  const cancelTokenSource = useRef(null);
  const handleCeremoniesSelect = (newValue) => {
    setSelectedCeremonies(newValue);
  };

  const loadCeremonies = async (searchQuery, loadedOptions, additional) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation canceled due to new request.");
    }
    cancelTokenSource.current = axios.CancelToken.source();

    return fetchCeremonies(searchQuery, loadedOptions, additional, cancelTokenSource.current.token);
  };

  // loading static tags
  const fetchTagsOptions = async (searchQuery, loadedOptions, additional = { page: 1 }) => {
    const { page } = additional;
    const limit = 10; // Define the limit of items per page
    const skip = (page - 1) * limit; // Calculate the skip value based on the current page

    const responseJSON = [
      { title: "Trending 1" },
      { title: "Trending 2" },
      { title: "Trending 3" },
      { title: "Trending 4" },
      { title: "Trending 5" },
      { title: "Naming 1" },
      { title: "Naming 2" },
      { title: "Naming 3" },
      { title: "Naming 4" },
      { title: "Naming 5" },
      { title: "Wedding 1" },
      { title: "Wedding 2" },
      { title: "Wedding 3" },
      { title: "Wedding 4" },
      { title: "Wedding 5" },
    ];

    return {
      options: responseJSON.map((tag) => ({
        value: tag.title,
        label: tag.title,
      })),
      hasMore: false, // Since this is static data, there are no more pages
      additional: {
        page,
      },
    };
  };

  const handleformOpen = () => {
    setFormOpen(true);
    setbutton(false);
    setupdatetable(false);
  };

  const handleFormClose = () => {
    setFormOpen(false);
    setbutton(true);
  };

  const handleTagsChange = (newValue) => {
    setSelectedTags(newValue);
  };

  // selected images
  const handleImages = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleVideos = (e) => {
    const files = Array.from(e.target.files);
    const newVideos = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setSelectedVideos((prevVideos) => [...prevVideos, ...newVideos]);
  };

  const handleRemoveImages = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveVideos = (index) => {
    setSelectedVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const cancelCeremonyFunc = () => {
    setShowForm(false);
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 6,
  };
  //
  const [items, setItems] = useState([{ id: 1, materialName: "", quantity: "" }]);
  const [savedItems, setSavedItems] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);

  const handleOpen = () => {
    setItems([{ id: 1, materialName: "", quantity: "" }]); // Reset the form when opened
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  const handleInputChange = (id, field, value) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, [field]: value } : item))
    );
  };

  const handleAdd = () => {
    setItems((prevItems) => [
      ...prevItems,
      { id: prevItems.length + 1, materialName: "", quantity: "" },
    ]);
  };

  const handleDelete = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    if (updatedItems.length === 0) {
      handleClose();
    }
  };

  const handleSave = () => {
    setSavedItems(items);
    setShowAddButton(false); // Hide the "Add Decor Items" button after saving
    handleClose();
  };

  const handleTextClick = () => {
    setItems(savedItems); // Load the saved items into the form
    setOpenModal(true);
  };

  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          mb={5}
          gap={5}
          style={{ paddingLeft: "11%" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "aliceblue", // Default text color
                    background: "blue", // Default background color
                    "&:hover": {
                      background: "blue", // Keep the background color the same on hover
                    },
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </LocalizationProvider>
          {button && (
            <Button
              style={{ textTransform: "capitalize", fontSize: 15, marginBottom: "0rem" }}
              variant="contained"
              color="dark"
              // onClick={() => setShowForm(true)}
              onClick={handleformOpen}
            >
              + Add Decor
            </Button>
          )}
        </Stack>
        {formOpen && (
          <Card>
            <h4 style={{ margin: "1rem 0rem 0rem 1rem" }}>Add Decor</h4>

            <CardContent>
              <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Title"
                    variant="standard"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "90%" }}
                  />
                </Col>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Budget"
                    variant="standard"
                    name="budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "90%" }}
                  />
                </Col>
                <Col style={{ marginLeft: "2rem" }}>
                  <Box style={{ marginLeft: "2rem" }}>
                    <Box
                      sx={{
                        display: "grid",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {showAddButton && (
                        <Button
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "white",
                            marginRight: "48vh",
                            marginTop: 25,
                          }}
                          variant="contained"
                          onClick={handleOpen}
                        >
                          Add Decor Items
                        </Button>
                      )}

                      <Modal
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                      >
                        <Box sx={modalStyle}>
                          <h3>Add Decor Items</h3>
                          {items.map((item, index) => (
                            <Box
                              key={item.id}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                alignItems: "center",
                                mb: 2,
                              }}
                            >
                              <TextField
                                label="Material Name"
                                variant="standard"
                                value={item.materialName}
                                onChange={(e) =>
                                  handleInputChange(item.id, "materialName", e.target.value)
                                }
                              />
                              <TextField
                                label="Quantity"
                                variant="standard"
                                type="number"
                                value={item.quantity}
                                onChange={(e) =>
                                  handleInputChange(item.id, "quantity", e.target.value)
                                }
                              />
                              <IconButton onClick={() => handleDelete(item.id)}>
                                <DeleteIcon />
                              </IconButton>
                              {index === items.length - 1 && (
                                <IconButton onClick={handleAdd}>
                                  <AddIcon style={{ color: "#7B809A" }} />
                                </IconButton>
                              )}
                            </Box>
                          ))}
                          <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
                            <Button
                              style={{
                                textTransform: "capitalize",
                                fontSize: 15,
                                color: "white",
                                backgroundColor: "black",
                                gap: 2,
                              }}
                              variant="contained"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                            <Button
                              style={{
                                textTransform: "capitalize",
                                fontSize: 15,
                                color: "black",
                                backgroundColor: "white",
                              }}
                              variant="contained"
                              onClick={handleClose}
                              sx={{ ml: 2 }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Modal>
                      <Box sx={{ p: 2, maxWidth: "100%" }}>
                        {savedItems.length > 0 && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              p: 2,
                              borderRadius: 1,
                              backgroundColor: "#f5f5f5", // Background color
                            }}
                          >
                            {savedItems.map((item) => (
                              <Box
                                key={item.id}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  p: 1,
                                  borderRadius: "8px", // Item background color
                                  width: "100%",
                                }}
                                onClick={() => handleTextClick(item)}
                              >
                                <Typography
                                  sx={{
                                    marginRight: "5rem",
                                    color: "#7B809A",
                                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.materialName}: {item.quantity}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Col>
              </Row>
              <Row
                className="align-items-center"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexWrap: "nowrap",
                  marginTop: "1rem",
                }}
              >
                <Col style={{ flex: 1, fontSize: "medium", paddingRight: "12px" }}>
                  <AsyncPaginate
                    isMulti
                    value={selectedDecors}
                    loadOptions={loadCeremonies}
                    onChange={handleCeremoniesSelect}
                    additional={{
                      page: 1,
                    }}
                    placeholder="Select Ceremonies"
                    styles={asyncPaginateSelectStyles}
                    className="decor-asyncPaginate"
                  />
                </Col>
                <Col style={{ flex: 1, fontSize: "medium" }}>
                  <AsyncPaginate
                    isMulti
                    value={selectedTags}
                    loadOptions={fetchTagsOptions}
                    onChange={handleTagsChange}
                    additional={{
                      page: 1,
                    }}
                    placeholder="Select tags"
                    styles={asyncPaginateSelectStyles}
                    className="decor-asyncPaginate"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    variant="standard"
                    name="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                    multiline
                    rows={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Notes"
                    variant="standard"
                    name="notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                    multiline
                    rows={2}
                  />
                </Col>
              </Row>
              <Row style={{ display: "flex", justifyContent: "flex-start" }}>
                <Col style={{ marginRight: "1rem" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      marginTop: 25,
                    }}
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                    value={selectedImages}
                  >
                    Upload Decor Images
                    <input
                      type="file"
                      hidden
                      name="images"
                      accept="image/*"
                      multiple
                      onChange={handleImages}
                    />
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "white",
                      marginTop: 25,
                    }}
                    variant="contained"
                    component="label"
                    startIcon={<VideoFile />}
                  >
                    Upload Decor Videos
                    <input
                      type="file"
                      hidden
                      name="videos"
                      accept="video/*"
                      multiple
                      onChange={handleVideos}
                    />
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "2.5rem",
                    marginTop: "20px",
                  }}
                >
                  {selectedImages.map((file, index) => (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        src={file.preview}
                        alt="Uploaded"
                        style={{ width: "200px", height: "200px", objectFit: "cover" }}
                      />
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemoveImages(index)}
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                          borderRadius: "5px 0px 0px 5px",
                          backgroundColor: "seagreen",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "auto",
                        }}
                      >
                        &times;
                      </Button>
                    </div>
                  ))}
                </div>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "2.5rem",
                  marginTop: "20px",
                }}
              >
                {selectedVideos.map((file, index) => (
                  <div key={index} style={{ position: "relative" }}>
                    <video
                      src={file.preview}
                      alt="Uploaded"
                      style={{ width: "200px", height: "200px", objectFit: "cover" }}
                      controls
                    />
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleRemoveVideos(index)}
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        borderRadius: "5px 0px 0px 5px",
                        backgroundColor: "seagreen",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "auto",
                      }}
                    >
                      &times;
                    </Button>
                  </div>
                ))}
              </div>
              <Row style={{ marginTop: "1rem" }}>
                {/* <CardActions style={{ justifyContent: "end" }}> */}
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "black",
                    backgroundColor: "white",
                  }}
                  variant="contained"
                  onClick={handleFormClose}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
                {/* </CardActions> */}
              </Row>
            </CardContent>
          </Card>
        )}
        <div>
          <Decorstable updatetable={updatetable} />
        </div>
      </DashboardLayout>
    </MDBox>
  );
}

export default Decors;
