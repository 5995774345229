import React, { useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { TableCell } from "@mui/material";
import "./Decorsimage.scss";

const DecorTableCell = ({ images = [], imageApiUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const decorImageClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const imageUrls = images.map((img) => `${imageApiUrl}${img}`);
  const displayedImages = imageUrls.slice(0, 3);

  return (
    <TableCell>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 40px)", gap: "5px" }}>
        {displayedImages.map((url, index) => (
          <div
            key={index}
            style={{
              width: "40px",
              height: "40px",
              cursor: "pointer",
              borderRadius: "5px",
              overflow: "hidden",
              position: "relative",
            }}
            onClick={() => decorImageClick(index)}
          >
            <img
              src={url}
              alt={`Image ${index + 1}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        ))}
        {images.length > 3 && (
          <div
            style={{
              width: "40px",
              height: "40px",
              cursor: "pointer",
              borderRadius: "5px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#ccc",
            }}
            onClick={() => decorImageClick(3)}
          >
            <span style={{ color: "white", fontWeight: "bold" }}>{`+${images.length - 3}`}</span>
          </div>
        )}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageUrls.length)}
        />
      )}
    </TableCell>
  );
};

DecorTableCell.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  imageApiUrl: PropTypes.string.isRequired,
};

DecorTableCell.defaultProps = {
  images: [],
};

export default DecorTableCell;
