import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TablePagination,
  Stack,
  Button,
} from "@mui/material";

import axios from "axios";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import showConfirmationDialog from "../../utils/sweetalert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";
import * as XLSX from "xlsx";

import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, disableNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={disableNext} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={disableNext} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableNext: PropTypes.bool.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  borderRadius: 3,
  p: 4,
  height: "50%",
};

export default function CartTable() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [disableNext, setDisableNext] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openModel, setOpenModel] = useState(false);
  const [allPagesData, setAllPagesData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      const skip = page * rowsPerPage;
      const limit = rowsPerPage;

      if (allPagesData[page]) {
        setCurrentPageData(allPagesData[page]);
        setDisableNext(allPagesData[page].length < rowsPerPage);
      } else {
        try {
          const response = await axios.get(`${API_URL}admin/payments`, {
            params: { skip, limit },
            headers: { Authorization: `Bearer ${token}` },
          });

          const newPageData = response.data;
          setAllPagesData((prevData) => ({
            ...prevData,
            [page]: newPageData,
          }));
          setCurrentPageData(newPageData);
          setDisableNext(newPageData.length < rowsPerPage);
        } catch (error) {
          console.error("Error fetching customer data:", error);
          setError("Error fetching data. Please try again later.");
        }
      }
    };

    fetchPageData();
  }, [page, rowsPerPage, token, allPagesData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //View
  const handleView = async (row) => {
    try {
      const response = await axios.get(`${API_URL}payment/${row._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSelectedPayment(response.data);
      setOpenModel(true);
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
    }
  };
  // delete method
  const handleDeleteOption = async (row) => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(`${API_URL}payment/${row._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response data:", response.data);

        // Refresh the data after deletion
        setAllPagesData((prevData) => {
          const updatedData = { ...prevData };
          const updatedPageData = updatedData[page].filter((item) => item._id !== row._id);
          updatedData[page] = updatedPageData;
          return updatedData;
        });
        setCurrentPageData((prevData) => prevData.filter((item) => item._id !== row._id));
        toast.success("Payment deleted successfully!");
      } catch (error) {
        console.error("Error deleting payment:", error);
        toast.error("Failed to delete payment.");
      } finally {
        setLoading(false);
      }
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClosed = () => {
    setOpenModel(false);
    setSelectedPayment(null);
  };

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleViewOption = async () => {
    handleMenuClose();
    if (selectedRow) {
      await handleView(selectedRow);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const handleExportClick = () => {
    const tableData = currentPageData.map((row) => ({
      BookingName: row.bookingID,
      Amount: row.amount,
      ModeOfPayment: row.modeOfPayment,
      PaymentDate: formatDate(row.paymentDate),
      TransactionId: row.transactionId,
      CreatedOn: handleformatDate(row.createdOn),
    }));

    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          style={{ paddingRight: "11%" }}
        ></Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "aliceblue", // Default text color
                  background: "blue", // Default background color
                  "&:hover": {
                    background: "blue", // Keep the background color the same on hover
                    color: "aliceblue", // Keep the text color the same on hover
                  },
                }}
                onClick={handleExportClick}
              >
                Export
              </Button>
            </Box>
          </div>
        </LocalizationProvider>
        <div style={{ marginTop: 40 }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
              aria-label="custom pagination table"
            >
              <TableRow style={{ background: "#49a3f1", height: "30%" }}>
                <TableCell style={{ fontWeight: "bold" }}>Booking Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Amount</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Mode Of Payment</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Payment Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Transaction Id</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
              <TableBody>
                {currentPageData && currentPageData.length > 0 ? (
                  currentPageData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 200, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.bookingID}
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.amount}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.modeOfPayment}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {formatDate(row.paymentDate)}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.transactionId}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {handleformatDate(row.createdOn)}
                      </TableCell>
                      <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <IconButton onClick={(event) => handleMenuOpen(event, row)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: "center", horizontal: "right" }}
                          transformOrigin={{ vertical: "center", horizontal: "left" }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                                fontFamily: "Roboto",
                                fontWeight: "450",
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={handleViewOption}>
                            <EditIcon sx={{ mr: 1 }} />
                            View
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={() => handleDeleteOption(row)}
                          >
                            <DeleteIcon sx={{ mr: 1 }} />
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={7}
                    count={
                      page * rowsPerPage + (disableNext ? currentPageData.length : rowsPerPage)
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={(subprops) => (
                      <TablePaginationActions {...subprops} disableNext={disableNext} />
                    )}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </DashboardLayout>

      {/* Modal for displaying cart details */}
      <Modal open={openModel} onClose={handleClosed}>
        <Box sx={style}>
          {selectedPayment && (
            <Card>
              <IconButton
                aria-label="close"
                onClick={handleClosed}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <CardHeader title="Payment Details" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Booking ID"
                      variant="standard"
                      value={selectedPayment.bookingID}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Description"
                      variant="standard"
                      value={selectedPayment.description}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      variant="standard"
                      value={selectedPayment.amount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Mode Of Payment"
                      variant="standard"
                      value={selectedPayment.modeOfPayment}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Repeat the structure for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Payment Date"
                      variant="standard"
                      value={selectedPayment.paymentDate}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Notes"
                      variant="standard"
                      value={selectedPayment.notes}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Add more Grid items for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Transaction Id"
                      variant="standard"
                      value={selectedPayment.transactionId}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Box>
      </Modal>
    </MDBox>
  );
}
