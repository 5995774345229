import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Button,
  Stack,
  TextField,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Teammemberstable from "../../layouts/teammembers/Teammemberstable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setTeamMembersStartDate } from "../../redux/slices/TeamMembersSlice";
import { setTeamMembersEndDate } from "../../redux/slices/TeamMembersSlice";

const formStyle = {
  position: "relative",
  top: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  marginBottom: 4,
};

function Teammembers() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    mail: "",
    contact: "",
    role: "",
  });
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [contact, setContact] = useState("");
  const token = sessionStorage.getItem("token");
  const [button, setbutton] = useState(true);
  const [startDate, setStartDate] = useState(null);
  // const [startDateFormatted, setStartDateFormatted] = useState(null);
  // const [endDateFormatted, setEndDateFormatted] = useState(null);
  // const [usersPageData, setUsersPageData] = useState([]);
  const usersPageData = useSelector((state) => state.teamMembers.usersPageData);
  const filterData = useSelector((state) => state.teamMembers.filterData);
  const [endDate, setEndDate] = useState(null);
  const [updatetable, setupdatetable] = useState(false);
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [teamMemberRole, setTeamMemberRole] = useState("");

  const startDateFormatted = useSelector((state) => state.teamMembers.teamMembersStartDate);
  const endDateFormatted = useSelector((state) => state.teamMembers.teamMembersEndDate);
  //
  const formatDate = (date) => {
    return date ? format(date, "dd/MM/yyyy") : null;
  };

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-GB") : null;
    console.log(formattedDate, "formattedDate");
    setStartDate(date); // Keep the original Date object in state
    // dispatch(setTeamMembersStartDate(date)); // Keep the original Date object in state
    dispatch(setTeamMembersStartDate(formattedDate)); // Store formatted string in state
    dispatch(setTeamMembersEndDate(null)); // Reset the end date when the start date changes
  };
  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.toLocaleDateString("en-GB") : null;
    console.log(formattedDate, "formattedDate");
    setEndDate(date); // Keep the original Date object in state
    // dispatch(setTeamMembersEndDate(date)); // Keep the original Date object in state
    dispatch(setTeamMembersEndDate(formattedDate)); // Store formatted string in state
  };
  //
  const handleDropdownChange = (event) => {
    setTeamMemberRole(event.target.value);
  };

  const handleOpen = () => {
    setFormOpen(true);
    setbutton(false);
    setupdatetable(false);
  };

  const handleClose = () => {
    setFormOpen(false);
    setbutton(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    // Handle form save logic here
    console.log("Form data saved:", formData);
    handleClose();
  };

  // Dropdowntags
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (nameToDelete) => {
    setPersonName((names) => names.filter((name) => name !== nameToDelete));
  };

  //Dropdowndecors
  // post method add form
  const handleSubmit = async () => {
    const payload = {
      name,
      password,
      mail,
      contact,
      teamMemberRole,
    };

    try {
      const response = await axios.post(`${API_URL}admin/register`, payload, {});
      setupdatetable(true);
      setbutton(true);
      setFormOpen(false);
      console.log("Response:", response.data);
      toast.success("Team member added successfully!");
      handleClose(); // Close the form after successful submission
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  // Handle tag selection change
  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    dispatch(setTeamMembersStartDate(null));
    dispatch(setTeamMembersEndDate(null));
  };

  const handleExportClick = () => {
    console.log("export click", filterData);

    if (filterData && filterData.length > 0) {
      try {
        // Map data to be exported
        const dataToExport = filterData.map((row) => ({
          Name: row.name,
          Email: row.mail,
          Contact: row.contact,
          TeamMemberRole: row.teamMemberRole,
          CreatedOn: row.createdOn, // Ensure this is formatted as needed
        }));

        console.log("Data to export", dataToExport);

        // Create a new workbook and add a worksheet
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users Data");

        // Generate a download link for the Excel file
        XLSX.writeFile(workbook, "UsersData.xlsx");
      } catch (error) {
        console.error("Failed to export data to Excel", error);
      }
    } else {
      console.warn("No data available for export.");
    }
  };
  useEffect(() => {
    console.log("endDate", endDateFormatted);
  }, [endDateFormatted]);

  return (
    <div>
      <MDBox>
        <DashboardLayout>
          <DashboardNavbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            mb={5}
            gap={5}
            style={{ paddingLeft: "11%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate} // Use the Date object for value
                    onChange={handleStartDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={startDateFormatted || ""} // Use the formatted date for display
                      />
                    )}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate} // Use the Date object for value
                    onChange={handleEndDateChange}
                    minDate={startDate ? new Date(startDate.getTime() + 86400000) : null} // + 1 day
                    disabled={!startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={endDateFormatted || ""} // Use the formatted date for display
                      />
                    )}
                  />
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "aliceblue", // Default text color
                      background: "blue", // Default background color
                      "&:hover": {
                        background: "blue", // Keep the background color the same on hover
                      },
                    }}
                    onClick={handleExportClick}
                  >
                    Export
                  </Button>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "Black ", // Default text color
                      background: "White", // Default background color
                      "&:hover": {
                        background: "White", // Keep the background color the same on hover
                      },
                    }}
                    onClick={handleClearFilter}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </div>
            </LocalizationProvider>
            {button && (
              <Button
                style={{ textTransform: "capitalize", fontSize: 15 }}
                variant="contained"
                color="dark"
                onClick={handleOpen}
              >
                + Add Teammember
              </Button>
            )}
          </Stack>

          {formOpen && (
            <Box sx={formStyle}>
              <Typography variant="h6" component="h2">
                Add Teammember
              </Typography>
              <CardContent>
                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Name"
                      variant="standard"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Password"
                      variant="standard"
                      name="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Mail"
                      variant="standard"
                      name="mail"
                      value={mail}
                      mail
                      onChange={(e) => setMail(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                </Row>
                <Row style={{ display: "flex" }}>
                  <Col>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Contact"
                      variant="standard"
                      name="contact"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                  <FormControl
                    sx={{
                      marginTop: "16px",
                      marginLeft: "9vh",
                      flexDirection: "column",
                      width: 300,
                    }}
                    variant="standard"
                  >
                    <InputLabel id="role-label">TeamMemberRole</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role-select"
                      value={teamMemberRole}
                      onChange={handleDropdownChange}
                      label="Role"
                    >
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Co-ordinator">Co-ordinator</MenuItem>
                      <MenuItem value="Customer Relationship Manager">
                        Customer Relationship Manager
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Row>
              </CardContent>
              <Row>
                <CardActions style={{ marginLeft: 15, justifyContent: "start" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "white",
                      backgroundColor: "black",
                    }}
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    onClick={handleClose}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Row>
            </Box>
          )}
          <div>
            <Teammemberstable updatetable={updatetable} />
          </div>
        </DashboardLayout>
      </MDBox>
    </div>
  );
}

export default Teammembers;
