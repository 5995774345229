import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Menu,
  TablePagination,
  Button,
  MenuItem,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer, toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { API_URL } from "../../utils/url";
import showConfirmationDialog from "../../utils/sweetalert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, disableNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={disableNext} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={disableNext} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableNext: PropTypes.bool.isRequired,
};

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1300,
  borderRadius: 3,
  p: 4,
  height: "50%",
};

export default function Bookings() {
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [disableNext, setDisableNext] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  // const [allPagesData, setAllPagesData] = useState({});
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPageData, setCurrentPageData] = useState(null);
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Get admin Status
  const getBookings = async (status) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}admin/bookingsByStatus/${status}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setCurrentPageData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const status = getStatusFromValue(value);
    getBookings(status);
  }, [value]);

  const getStatusFromValue = (value) => {
    switch (value) {
      case 0:
        return "upcoming";
      case 1:
        return "completed";
      case 2:
        return "cancelled";
      default:
        return "upcoming";
    }
  };

  // View
  const moreVertIconClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleview = async (row) => {
    handleCloseMenu();
    try {
      const response = await axios.get(`${API_URL}booking/${row._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSelectedBooking(response.data.data); // Corrected response.data to response.data.data
      setModelOpen(true);
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  const handleClosed = () => {
    setModelOpen(false);
    setSelectedBooking(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    p: 4,
  };

  // delete method
  const handleDeleteClick = async (e, id) => {
    e.preventDefault(); // Prevent default action, if necessary
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${API_URL}booking/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Booking deleted successfully.");
        setCurrentPageData(currentPageData.filter((item) => item._id !== id));
        setLoading(false);
        toast.success("Booking deleted successfully!");
      } catch (error) {
        console.error("Error deleting ceremony:", error);
        toast.error("Error deleting booking!"); // Optional: Show an error toast
      }
    }
  };
  // toggle button functionality

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };
  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "aliceblue", // Default text color
                    background: "blue", // Default background color
                    "&:hover": {
                      background: "blue", // Keep the background color the same on hover
                    },
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </LocalizationProvider>
          <div style={{ marginTop: 10 }}>
            <Box sx={{ width: "60%" }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Upcoming" />
                <Tab label="Completed" />
                <Tab label="Cancelled" />
              </Tabs>
            </Box>
          </div>
          <TableContainer sx={{ marginTop: "10px" }} component={Paper}>
            <Table
              sx={{
                minWidth: 500,
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              }}
              aria-label="custom pagination table"
            >
              <TableRow style={{ background: "#49a3f1", height: "30%" }}>
                <TableCell style={{ fontWeight: "bold" }}>Decor Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Customer Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Contact Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Budget</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Booking Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
              <TableBody>
                {currentPageData && currentPageData.length > 0 ? (
                  currentPageData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 200, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.decorID?.title}
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.customerID?.name}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.contactName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.address}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.budget}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {handleformatDate(row.createdOn)}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.bookingStatus}
                      </TableCell>
                      <TableCell sx={{ width: 230, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <IconButton aria-label="action" onClick={(e) => moreVertIconClick(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedRow === row}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: "center", horizontal: "right" }}
                          transformOrigin={{ vertical: "center", horizontal: "left" }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                                fontFamily: "Roboto",
                                fontWeight: "450",
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleview(row)}>
                            <VisibilityIcon sx={{ mr: 1 }} />
                            View
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={(e) => handleDeleteClick(e, row._id)}
                          >
                            <DeleteIcon sx={{ mr: 1 }} />
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={7}
                    count={
                      page * rowsPerPage + (disableNext ? currentPageData.length : rowsPerPage)
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // onPageChange={handleChangePage}
                    ActionsComponent={(subprops) => (
                      <TablePaginationActions {...subprops} disableNext={disableNext} />
                    )}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </DashboardLayout>

      {/* Modal for displaying cart details */}
      <Modal open={modelOpen} onClose={handleClosed}>
        <Box sx={style}>
          {selectedBooking && (
            <Card>
              <IconButton
                aria-label="close"
                onClick={handleClosed}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <CardHeader title="Booking Details" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Customer ID"
                      variant="standard"
                      value={selectedBooking.customerID}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Decor ID"
                      variant="standard"
                      value={selectedBooking.decorID}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Latlong"
                      variant="standard"
                      value={selectedBooking.latlong}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Paid Amount"
                      variant="standard"
                      value={selectedBooking.paidAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Repeat the structure for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="standard"
                      value={selectedBooking.address}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Pincode"
                      variant="standard"
                      value={selectedBooking.pincode}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Add more Grid items for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Contact"
                      variant="standard"
                      value={selectedBooking._id}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Latitude"
                      variant="standard"
                      value={selectedBooking.customerID}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="showRatingsToCustomer"
                      variant="standard"
                      value={selectedBooking.showRatingsToCustomer}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="ratingsFromCustomer"
                      variant="standard"
                      value={selectedBooking.ratingsFromCustomer}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Decor Items"
                      variant="standard"
                      value={selectedBooking.decorItems}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Budget"
                      variant="standard"
                      value={selectedBooking.budget}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="ratings"
                      variant="standard"
                      value={selectedBooking.ratings}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Tags"
                      variant="standard"
                      value={selectedBooking.tags}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Notes"
                      variant="standard"
                      value={selectedBooking.notes}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="isCartAdded"
                      variant="standard"
                      value={selectedBooking.isCartAdded}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Contact Name"
                      variant="standard"
                      value={selectedBooking.contactName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Contact"
                      variant="standard"
                      value={selectedBooking.contact}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Location"
                      variant="standard"
                      value={selectedBooking.location}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Date"
                      variant="standard"
                      value={new Date(selectedBooking.date).toLocaleDateString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Start Time"
                      variant="standard"
                      value={new Date(selectedBooking.startTime).toLocaleTimeString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="End Time"
                      variant="standard"
                      value={new Date(selectedBooking.endTime).toLocaleTimeString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Booking Status"
                      variant="standard"
                      value={selectedBooking.bookingStatus}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Payment ID"
                      variant="standard"
                      value={selectedBooking.paymentID}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Payment ID"
                      variant="standard"
                      value={selectedBooking.paymentStatus}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Payment ID"
                      variant="standard"
                      value={selectedBooking.paidAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Payment ID"
                      variant="standard"
                      value={selectedBooking.balanceAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Notes"
                      variant="standard"
                      value={selectedBooking.notes}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Box>
      </Modal>
      <ToastContainer />
    </MDBox>
  );
}
