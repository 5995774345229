import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import showConfirmationDialog from "../../utils/sweetalert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCeremonies } from "../../redux/slices/CategoriesSlice";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, disableNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableNext: PropTypes.bool.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "60%",
};

export default function CustomPaginationActionsTable(updatetable) {
  // const [ceremonies, setCeremonies] = useState(null);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [enlargedImageSrc, setEnlargedImageSrc] = useState("");
  const ceremonies = useSelector((state) => state.categories.ceremonies);
  const startDateFormatted = useSelector((state) => state.categories.categoriesStartDate);
  const endDateFormatted = useSelector((state) => state.categories.categoriesEndDate);
  const [disableNext, setDisableNext] = useState(false);
  const [allPagesData, setAllPagesData] = useState([]);
  const [users, setUsers] = useState([]);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    notes: "",
    decors: "",
    tags: "",
    upload: "",
    createdOn: "",
    updatedOn: "",
  });
  const [formData, setFormData] = useState({
    profile: "",
    name: "",
    mail: "",
    contact: "",
    role: "",
    updatedOn: "",
    createdOn: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  const token = sessionStorage.getItem("token");
  const [editorview, setEditorView] = useState("");

  const ceremoniesChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ceremonyImageClick = (src) => {
    console.log("Image clicked:", src); // Debugging line
    setEnlargedImageSrc(src);
    // setOpen(true);
  };
  const CloseCeremonyimage = () => {
    console.log("Modal closed"); // Debugging line
    // setOpen(false);
    setEnlargedImageSrc("");
  };

  //formate date
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return ""; // Return an empty string or a default value if the timestamp is invalid
    }
    const date = new Date(Number(timestamp)); // Ensure timestamp is a number
    return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  // Get method
  const getData = async () => {
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;

    if (allPagesData[page]) {
      dispatch(setCeremonies(allPagesData[page]));
      setDisableNext(allPagesData[page].length < rowsPerPage);
      console.log("fetch users first if");
    } else {
      console.log("fetch users second if");
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}ceremonies`, {
          params: { skip, limit, startDate: startDateFormatted, endDate: endDateFormatted },
          headers: { Authorization: `Bearer ${token}` },
        });

        const newPageData = response.data.data; // Ensure this matches your API structure
        setAllPagesData((prevData) => ({
          ...prevData,
          [page]: newPageData,
        }));
        dispatch(setCeremonies(response.data));
        setDisableNext(newPageData.length < rowsPerPage);
        setUsers(newPageData); // Ensure this updates the table data
        // const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
        // saveAs(blob, "FilteredData.csv");
      } catch (error) {
        console.error("Error fetching user data:", error);
        // setError("Error fetching data. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenView = (row, data) => {
    setEditorView(data);
    setEditData({
      title: row.title,
      description: row.description,
      notes: row.notes,
      decors: row.decors.map((decor) => decor.title).join(", "),
      tags: row.tags,
      upload: row.upload,
      createdOn: row.createdOn,
      updatedOn: row.updatedOn,
    });
    setSelectedId(row._id);
    setModelOpen(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setModelOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("event target: " + event.target);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditCeremony = async () => {
    try {
      const response = await axios.put(`${API_URL}ceremony/${selectedId}`, editData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Ceremony updated successfully:", response.data);

      // Update the ceremonies state
      setCeremonies((prevCeremonies) =>
        prevCeremonies.map((ceremony) =>
          ceremony.id === selectedId ? response.data.data : ceremony
        )
      );
      setLoading(false);
      handleCloseMenu();
      toast.success("Ceremony deleted successfully!");
    } catch (error) {
      console.error("Error updating ceremony:", error);
      setLoading(false);
    }
  };
  // delete method
  const handleDeleteCeremony = async () => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${API_URL}ceremony/${selectedId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //
        const updatedUsersPageData = ceremonies.filter((ceremony) => ceremony._id !== selectedId);
        dispatch(setCeremonies(updatedUsersPageData));
        setUsers(updatedUsersPageData); // Update the table data
        setAllPagesData((prevData) => ({
          ...prevData,
          [page]: updatedUsersPageData,
        }));
        setLoading(false);
        toast.success("User deleted successfully!");
      } catch (error) {
        console.error("Error deleting ceremony:", error);
        setLoading(false);
      }
    } else {
      console.log("Deletion canceled.");
    }
  };

  // view

  // useEffect(() => {
  //   console.log("ceremonies", ceremonies);
  // }, [ceremonies]);
  useEffect(() => {
    if (updatetable) {
      getData();
    }
  }, [page, rowsPerPage, token, allPagesData, updatetable, startDateFormatted, endDateFormatted]);

  useEffect(() => {
    setAllPagesData([]);
    console.log("end date formatted use effect", endDateFormatted);
    if (endDateFormatted != "") {
      console.log("end date formatted after if", endDateFormatted);
      getData();
      console.log("end date formatted after fetch users function", endDateFormatted);
    }
  }, [endDateFormatted]);

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Decors</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Tags</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Notes</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Upload</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {console.log("hfchggv ceremonies", ceremonies)}
          {/* {ceremonies &&
            ceremonies.map((row, index) => ( */}
          {/* {ceremonies?.map((row) => ( */}
          {ceremonies && ceremonies.length > 0 ? (
            ceremonies.map((row) => (
              <TableRow key={row._id}>
                <TableCell sx={{ width: 20 }}></TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ width: 120, fontFamily: '"Roboto"', fontWeight: "450" }}
                >
                  {row.title}
                </TableCell>
                <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {row.decors.map((decor) => decor.title).join("")}
                </TableCell>
                <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {row.tags}
                </TableCell>
                <TableCell sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {row.description}
                </TableCell>
                <TableCell sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {row.notes}
                </TableCell>
                <div>
                  <TableCell>
                    <img
                      src={`${IMAGEAPI_URL}${row.images}`}
                      alt="Banner"
                      style={{ maxWidth: "60%", cursor: "pointer", borderRadius: "5px" }}
                      onClick={() => ceremonyImageClick(`${IMAGEAPI_URL}${row.images}`)}
                    />
                  </TableCell>

                  {enlargedImageSrc && (
                    <Box
                      sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(0, 0, 0, 0.3)",
                        zIndex: 1200,
                      }}
                      BackdropProps={{
                        style: { background: "rgba(0, 0, 0, 0.5)" },
                      }}
                      onClick={CloseCeremonyimage}
                    >
                      <img
                        src={enlargedImageSrc}
                        alt="Enlarged Banner"
                        style={{
                          maxWidth: "50%",
                          maxHeight: "50%",
                          objectFit: "contain",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </Box>
                  )}
                </div>
                <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  {handleformatDate(row.createdOn)}
                </TableCell>
                <TableCell sx={{ width: 130, fontFamily: '"Roboto"', fontWeight: "450" }}>
                  <IconButton aria-label="action" onClick={(e) => handleClick(e, row._id)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedId === row._id}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "center", horizontal: "right" }}
                    transformOrigin={{ vertical: "center", horizontal: "left" }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        width: 140,
                        "& .MuiMenuItem-root": {
                          px: 1,
                          typography: "body2",
                          borderRadius: 0.75,
                          fontFamily: "Roboto",
                          fontWeight: "450",
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleOpenView(row, "View")}>
                      <VisibilityIcon sx={{ mr: 1 }} />
                      View
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenView(row, "Edit")}>
                      <EditIcon sx={{ mr: 1 }} />
                      Edit
                    </MenuItem>

                    <MenuItem sx={{ color: "error.main" }} onClick={handleDeleteCeremony}>
                      <DeleteIcon sx={{ mr: 1 }} />
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow style={{ height: 53 }}>
              <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={7}
              count={page * rowsPerPage + (disableNext ? ceremonies.length : rowsPerPage)}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={ceremoniesChangePage}
              ActionsComponent={(subprops) => (
                <TablePaginationActions {...subprops} disableNext={disableNext} />
              )}
            />
          </TableRow>
          <Modal open={modelOpen} onClose={handleCloseDialog}>
            <Box sx={style}>
              {editorview === "View" && (
                <IconButton
                  aria-label="close"
                  onClick={handleCloseDialog}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <TextField
                fullWidth
                margin="normal"
                label="Title"
                variant="standard"
                name="title"
                value={editData.title}
                onChange={handleInputChange}
                sx={{ flexDirection: "column", gap: 2, width: 230 }}
                style={{ margin: 25 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                variant="standard"
                name="description"
                value={editData.description}
                onChange={handleInputChange}
                sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Notes"
                variant="standard"
                name="notes"
                value={editData.notes}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Decors"
                variant="standard"
                name="decors"
                value={editData.decors}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Tags"
                variant="standard"
                name="tags"
                value={editData.tags}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
                disabled={editorview === "View"}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              {editorview === "View" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="CreatedOn"
                    variant="standard"
                    name="createdOn"
                    value={formatDate(editData.createdOn)}
                    onChange={handleInputChange}
                    sx={{
                      marginLeft: 3,
                      marginTop: 1,
                      flexDirection: "column",
                      gap: 2,
                      width: 230,
                    }}
                    disabled
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="UpdatedOn"
                    variant="standard"
                    name="updatedOn"
                    value={formatDate(editData.updatedOn)}
                    onChange={handleInputChange}
                    sx={{
                      marginLeft: 3,
                      marginTop: 1,
                      flexDirection: "column",
                      gap: 2,
                      width: 230,
                    }}
                    disabled
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                  />
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 8,
                  colSpan: 4,
                  marginRight: 4,
                }}
              >
                {editorview !== "View" && (
                  <>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "black",
                      }}
                      onClick={handleEditCeremony}
                    >
                      Update
                    </Button>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        fontSize: 15,
                        color: "black",
                        backgroundColor: "white",
                      }}
                      variant="outlined"
                      onClick={handleCloseDialog}
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
