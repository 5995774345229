/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Teammembers from "layouts/teammembers";
import Teammembers from "layouts/teammembers/Teammembers";
import Cermonies_Management from "layouts/cermonies_management";
import Bookings from "layouts/bookings_management/Bookings";
import Customer_Management from "layouts/customer_management";
import Tickets from "layouts/tickets/Tickets";
import Banners from "layouts/banners";
import Analytics_and_Reporting from "layouts/analytics_and_reporting";
import Settings from "layouts/settings";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Notification from "layouts/notification/Notification";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
import Login from "layouts/authentication/login";
// @mui icons
import Icon from "@mui/material/Icon";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CategoryIcon from "@mui/icons-material/Category";
import BookIcon from "@mui/icons-material/Book";
// import AnalyticsIcon from "@mui/icons-material/Analytics";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Decors from "layouts/decors/Decors";
import Ratings from "layouts/ratings/Ratings";
import Address from "layouts/address/Address";
import Cart_table from "layouts/cart/Cart_table";
import Payment_table from "layouts/payment/Payment_table";
import { NotificationAdd } from "@mui/icons-material";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <DashboardIcon fontSize="small">Dashboard</DashboardIcon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Teammembers",
    key: "teammembers",
    icon: <ManageAccountsIcon fontSize="small">Teammembers</ManageAccountsIcon>,
    route: "/teammembers",
    component: <Teammembers />,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <CategoryIcon fontSize="small">Categories</CategoryIcon>,
    route: "/categories",
    component: <Cermonies_Management />,
  },
  {
    type: "collapse",
    name: "Services",
    key: "decors",
    icon: <ManageHistoryIcon fontSize="small">Services</ManageHistoryIcon>,
    route: "/decors",
    component: <Decors />,
  },
  {
    type: "collapse",
    name: "Bookings",
    key: "bookings_management",
    icon: <BookIcon fontSize="small">Bookings</BookIcon>,
    route: "/bookings_management",
    component: <Bookings />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customer_management",
    icon: <SupportAgentIcon fontSize="small">Customers</SupportAgentIcon>,
    route: "/customer_management",
    component: <Customer_Management />,
  },

  {
    type: "collapse",
    name: "Tickets",
    key: "tickets",
    icon: <ConfirmationNumberIcon fontSize="small">Tickets</ConfirmationNumberIcon>,
    route: "/tickets",
    component: <Tickets />,
  },

  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <ViewCarouselIcon fontSize="small">Banners</ViewCarouselIcon>,
    route: "/banners",
    component: <Banners />,
  },
  {
    type: "collapse",
    name: "Ratings",
    key: "ratings",
    icon: <StarHalfIcon fontSize="small">ratings</StarHalfIcon>,
    route: "/ratings",
    component: <Ratings />,
  },

  {
    type: "collapse",
    name: "Address",
    key: "address",
    icon: <Icon fontSize="small">address</Icon>,
    route: "/address",
    component: <Address />,
  },
  {
    type: "collapse",
    name: "Carts",
    key: "cart_table",
    icon: <ShoppingCartIcon fontSize="small">Cart table</ShoppingCartIcon>,
    route: "/cart_table",
    component: <Cart_table />,
  },

  // {
  //   type: "collapse",
  //   name: "Analytics and Reporting",
  //   key: "analytics_and_reporting",
  //   icon: <AnalyticsIcon fontSize="small">Analytics and Reportings</AnalyticsIcon>,
  //   route: "/analytics_and_reporting",
  //   component: <Analytics_and_Reporting />,
  // },

  {
    type: "collapse",
    name: "Payment",
    key: "payment_management",
    icon: <PaymentIcon fontSize="small">Payment</PaymentIcon>,
    route: "/payment_management",
    component: <Payment_table />,
  },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <SettingsApplicationsIcon fontSize="small">Settings</SettingsApplicationsIcon>,
    route: "/settings",
    component: <Settings />,
  },

  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },

  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Notification",
    key: "notification",
    icon: <NotificationAdd fontSize="small">notification</NotificationAdd>,
    route: "/notification",
    component: <Notification />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    // type: "collapse",
    // name: "Login",
    // key: "login",
    // icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/login",
    component: <Login />,
  },
];

export default routes;
