import React, { useEffect, useRef } from "react";

const MapComponent = () => {
  const mapRef = useRef(null);
  const polygonRef = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBuMSyLR_cIVNPf6QeQy8R6Vho12Etm79c`;
      googleMapsScript.async = true;
      googleMapsScript.defer = true;
      googleMapsScript.onload = initializeMap;
      googleMapsScript.onerror = () => {
        console.error("Error loading Google Maps script.");
      };
      document.head.appendChild(googleMapsScript);
    };

    const initializeMap = () => {
      const google = window.google;
      if (!google) {
        console.error("Google Maps API failed to load.");
        return;
      }

      const mapCenter = { lat: 13.0827, lng: 80.2707 }; // Chennai coordinates
      const map = new google.maps.Map(mapRef.current, {
        zoom: 12,
        center: mapCenter,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      // Define the LatLng coordinates for the polygon.
      const polygonCoords = [
        { lat: 13.0927, lng: 80.2707 }, // Top point
        { lat: 13.0827, lng: 80.2807 }, // Right point
        { lat: 13.0727, lng: 80.2707 }, // Bottom point
        { lat: 13.0827, lng: 80.2607 }, // Left point
        { lat: 13.0927, lng: 80.2707 }, // Closing the polygon
      ];

      // Construct the polygon.
      const polygon = new google.maps.Polygon({
        paths: polygonCoords,
        draggable: true,
        editable: true,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });

      polygon.setMap(map);
      polygonRef.current = polygon;

      google.maps.event.addListener(polygon.getPath(), "insert_at", getPolygonCoords);
      google.maps.event.addListener(polygon.getPath(), "set_at", getPolygonCoords);
    };

    const getPolygonCoords = () => {
      const len = polygonRef.current.getPath().getLength();
      let htmlStr = "";
      for (let i = 0; i < len; i++) {
        htmlStr += `new google.maps.LatLng(${polygonRef.current
          .getPath()
          .getAt(i)
          .toUrlValue(5)}), `;
      }
      document.getElementById("info").innerHTML = htmlStr;
    };

    loadGoogleMapsScript();

    return () => {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }
    };
  }, []);

  return (
    <div>
      <div ref={mapRef} style={{ height: "500px", width: "100%" }} />
      <div id="info" style={{ marginTop: "10px" }} />
    </div>
  );
};

export default MapComponent;
