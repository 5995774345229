import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableCell,
  TableRow,
  Paper,
  IconButton,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  TablePagination,
  Stack,
  Button,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { API_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, disableNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={disableNext} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={disableNext} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableNext: PropTypes.bool.isRequired,
};

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  borderRadius: 3,
  p: 4,
  height: "50%",
};

export default function CartTable() {
  const [open, setOpen] = useState(false);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [disableNext, setDisableNext] = useState(false);
  const [selectedCart, setSelectedCart] = useState(null);
  const [modeOpen, setmodelOpen] = useState(false);
  const [allPagesData, setAllPagesData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fetchPageData = async () => {
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;

    if (allPagesData[page]) {
      setCurrentPageData(allPagesData[page]);
      setDisableNext(allPagesData[page].length < rowsPerPage);
    } else {
      try {
        const response = await axios.get(`${API_URL}adminCarts`, {
          params: { skip, limit },
          headers: { Authorization: `Bearer ${token}` },
        });

        const newPageData = response.data.data;
        setAllPagesData((prevData) => ({
          ...prevData,
          [page]: newPageData,
        }));
        setCurrentPageData(newPageData);
        setDisableNext(newPageData.length < rowsPerPage);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        setError("Error fetching data. Please try again later.");
      }
    }
  };
  useEffect(() => {
    fetchPageData();
  }, [page, rowsPerPage, token, allPagesData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleView = async (row) => {
    try {
      const response = await axios.get(`${API_URL}adminCarts/${row._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSelectedCart(response.data);
      setmodelOpen(true);
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
    }
  };

  const modelClosed = () => {
    setmodelOpen(false);
    setSelectedCart(null);
  };
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const handleExportClick = () => {
    const data = currentPageData.map((row) => ({
      "Customer Name": row.customerId?.name,
      "Decor Name": row.decorId?.title,
      Quantity: row.quantity,
      "Total Amount": row.totalAmount,
      Address: row.address,
      Date: row.date,
      "Created On": handleformatDate(row.createdOn),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Decorations");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "decorations.xlsx");
  };

  //
  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return ""; // Return an empty string or a default value if the timestamp is invalid
    }
    const date = new Date(Number(timestamp)); // Ensure timestamp is a number
    return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "aliceblue", // Default text color
                    background: "blue", // Default background color
                    "&:hover": {
                      background: "blue", // Keep the background color the same on hover
                      color: "aliceblue", // Keep the text color the same on hover
                    },
                  }}
                  onClick={handleExportClick}
                >
                  Export
                </Button>
              </Box>
            </div>
          </LocalizationProvider>
        </Stack>
        <div style={{ marginTop: 10 }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
              aria-label="custom pagination table"
            >
              <TableRow style={{ background: "#49a3f1", height: "30%" }}>
                <TableCell style={{ fontWeight: "bold" }}>Customer Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Decor Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Total amount</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Address</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
              <TableBody>
                {currentPageData && currentPageData.length > 0 ? (
                  currentPageData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 200, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.customerId?.name}
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.decorId?.title}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.quantity}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.totalAmount}
                      </TableCell>
                      <TableCell sx={{ width: 280, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.address}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.date}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {handleformatDate(row.createdOn)}
                      </TableCell>
                      <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <IconButton onClick={() => handleView(row)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={7}
                    count={
                      page * rowsPerPage + (disableNext ? currentPageData.length : rowsPerPage)
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={(subprops) => (
                      <TablePaginationActions {...subprops} disableNext={disableNext} />
                    )}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </DashboardLayout>

      {/* Modal for displaying cart details */}
      <Modal open={modeOpen} onClose={modelClosed}>
        <Box sx={style}>
          {selectedCart && (
            <Card>
              <IconButton
                aria-label="close"
                onClick={modelClosed}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <CardHeader title="Cart Details" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Customer ID"
                      variant="standard"
                      value={selectedCart.customerId}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Decor ID"
                      variant="standard"
                      value={selectedCart.decorId}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Quantity"
                      variant="standard"
                      value={selectedCart.quantity}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Total Amount"
                      variant="standard"
                      value={selectedCart.totalAmount}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Repeat the structure for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Percentage"
                      variant="standard"
                      value={selectedCart.percentage}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Contact Name"
                      variant="standard"
                      value={selectedCart.contactName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  {/* Add more Grid items for other fields */}
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Contact"
                      variant="standard"
                      value={selectedCart.contact}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Latitude"
                      variant="standard"
                      value={selectedCart.latlong[0]}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Longitude"
                      variant="standard"
                      value={selectedCart.latlong[1]}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Address"
                      variant="standard"
                      value={selectedCart.address}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Pincode"
                      variant="standard"
                      value={selectedCart.pincode}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="State"
                      variant="standard"
                      value={selectedCart.state}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Date"
                      variant="standard"
                      value={new Date(selectedCart.date).toLocaleDateString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Start Time"
                      variant="standard"
                      value={new Date(selectedCart.startTime).toLocaleTimeString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="End Time"
                      variant="standard"
                      value={new Date(selectedCart.endTime).toLocaleTimeString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Cart Tax"
                      variant="standard"
                      value={selectedCart.cartTax}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Cart Amount Without Tax"
                      variant="standard"
                      value={selectedCart.cartAmountWithoutTax}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Cart Amount With Tax"
                      variant="standard"
                      value={selectedCart.cartAmountWithTax}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Cart Partial Amount Without Tax"
                      variant="standard"
                      value={selectedCart.cartPartialAmountWithoutTax}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Cart Partial Amount With Tax"
                      variant="standard"
                      value={selectedCart.cartPartialAmountWithTax}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Created On"
                      variant="standard"
                      value={formatDate(selectedCart.createdOn)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      label="Updated On"
                      variant="standard"
                      value={formatDate(selectedCart.updatedOn)}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Box>
      </Modal>
    </MDBox>
  );
}
