import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Stack,
  Box,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import { AsyncPaginate } from "react-select-async-paginate";
import { Rating } from "@mui/material";
import { Star as StarIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import Ratingstable from "layouts/ratings/Ratingstable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";

function Ratings() {
  const [showRatingsform, setshowRatingsform] = useState(false);
  const [comment, setComment] = useState("");
  const [decor, setDecor] = useState([]);
  const [rating, setRatings] = useState(0);
  const [isActive, setActive] = useState(true);
  const [ratingsSelectData, setRatingsSelectData] = useState([]);
  const [ratingID, setRatingID] = useState();
  const [loading, setLoading] = useState(false);
  const token = sessionStorage.getItem("token");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChange = (event, newValue) => {
    setRatings(newValue);
  };
  const asyncPaginateSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "2px",
      boxShadow: "none",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      borderRadius: "16px",
      padding: "2px 8px",
      margin: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#aaa",
      cursor: "pointer",
      ":hover": {
        color: "#333",
      },
    }),
    control: (base) => ({
      ...base,
      variant: "standard",
      border: "none",
      borderBottom: "1px solid #ccc",
      boxShadow: "none",
    }),
    container: (base) => ({
      ...base,
      marginBottom: "20px",
    }),
  };

  const loadCeremonies = async (searchQuery, loadedOptions, additional) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Operation canceled due to new request.");
    }
    cancelTokenSource.current = axios.CancelToken.source();

    return fetchRatings(searchQuery, loadedOptions, additional, cancelTokenSource.current.token);
  };

  const cancelRatingsFunc = () => {
    setshowRatingsform(false);
  };

  // get method

  const loadRatings = async (search, loadedOptions, { page }, token) => {
    try {
      const response = await axios.get(`${API_URL}decors`, {
        headers: {
          Authorization: `Bearer ${token}`, // Setting Authorization header with token
        },
      });

      const data = response.data.data;

      const options = data.map((rating) => ({
        value: rating._id,
        label: rating.title,
      }));

      return {
        options,
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error loading rating:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const ratingSelectData = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.value);
    setDecor(selectedLabels);
    setRatingsSelectData(selectedOptions);
  };

  // post method
  const saveRatingsform = async () => {
    setLoading(true);
    try {
      if (rating < 1 || rating > 5) {
        toast.error("Ratings must be between 1 and 5");
        setLoading(false);
        return;
      }

      const formData = {
        isActive,
        comment,
        rating: Number(rating),
      };

      const res = await axios({
        method: "POST",
        url: `${API_URL}decor/${decor}/adminRating`,
        data: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response", res.data);
      if (res.status === 201) {
        toast.success("Added successfully!");
        setActive(true);
        setDecor([]); // Reset decor as needed
        setComment("");
        setRatingsSelectData([]);
        setRatings(0); // Save posted data
      }
    } catch (err) {
      if (err.response) {
        toast.error(`Error: ${err.response.data.message || "Registration failed"}`);
        console.error(`Error response status: ${err.response.status}`);
        console.error("Error response data:", err.response.data);
      } else if (err.request) {
        toast.error("No response received from server");
        console.error("No response received:", err.request);
      } else {
        toast.error(`Error: ${err.message}`);
        console.error("Error:", err.message);
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (ratingsSelectData && ratingsSelectData.length > 0) {
      setRatingID(ratingsSelectData[0].value);
    } else {
      setRatingID(null);
    }
  }, [ratingsSelectData]);
  console.log(ratingID, "ratingID");
  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          mb={5}
          gap={5}
          style={{ paddingLeft: "11%" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Button
                  sx={{
                    textTransform: "capitalize",
                    color: "aliceblue", // Default text color
                    background: "blue", // Default background color
                    "&:hover": {
                      background: "blue", // Keep the background color the same on hover
                    },
                  }}
                >
                  Export
                </Button>
              </Box>
            </div>
          </LocalizationProvider>
          {!showRatingsform && (
            <Button
              style={{ textTransform: "capitalize", fontSize: 15, marginBottom: "0rem" }}
              variant="contained"
              color="dark"
              onClick={() => setshowRatingsform(true)}
            >
              + Add Ratings
            </Button>
          )}
        </Stack>
        {showRatingsform && (
          <Card>
            <h4 style={{ margin: "1rem 0rem 0rem 1rem" }}>Add Ratings</h4>

            <CardContent>
              <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                {/* <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Customer"
                    variant="standard"
                    name="customer"
                    value={customer}
                    onChange={(e) => setCustomer(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "90%" }}
                  />
                </Col> */}

                <Col style={{ width: "200px", flex: 1, marginTop: 40, paddingRight: "45px" }}>
                  <AsyncPaginate
                    isMulti
                    value={ratingsSelectData}
                    loadOptions={loadRatings}
                    onChange={ratingSelectData}
                    additional={{
                      page: 1,
                    }}
                    placeholder="Decor Item"
                    styles={asyncPaginateSelectStyles}
                    className="decor-asyncPaginate"
                  />
                </Col>
                <Col style={{ flex: 1, marginTop: "25px" }}>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Isactive</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={isActive}
                      onChange={(e) => setActive(e.target.value)}
                      label="Isactive"
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col style={{ flex: 1, marginTop: "30px" }}>
                  <div>
                    <h5
                      style={{
                        flex: 1,
                        borderRadius: "8px",
                      }}
                    >
                      Ratings
                    </h5>
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      onChange={(e) => setRatings(e.target.value)}
                      icon={
                        <StarIcon
                          fontSize="inherit"
                          style={{
                            flex: 1,
                            borderRadius: "8px",
                          }}
                        />
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Comment"
                    variant="standard"
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                    multiline
                    rows={2}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: "1rem" }}>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "white",
                    backgroundColor: "black",
                  }}
                  variant="contained"
                  onClick={saveRatingsform}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Save"}
                </Button>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "black",
                    backgroundColor: "white",
                  }}
                  variant="contained"
                  onClick={cancelRatingsFunc}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
                {/* </CardActions> */}
              </Row>
            </CardContent>
          </Card>
        )}
        <div style={{ marginTop: "50px" }}>
          <Ratingstable />
        </div>
      </DashboardLayout>
    </MDBox>
  );
}

export default Ratings;
