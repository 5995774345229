import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Star as StarIcon } from "@mui/icons-material";
import { Rating } from "@mui/material";
import { API_URL } from "../../utils/url";
import showConfirmationDialog from "../../utils/sweetalert";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "40%",
};

export default function CustomPaginationActionsTable() {
  const [ratings, setRatings] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    customer: "",
    decorId: "",
    comment: "",
    isActive: false,
    ratings: 0,
  });

  const [ratingsdata, setRatingsdata] = useState([]);
  const [editData, setEditData] = useState({
    customer: "",
    decorId: "",
    comment: "",
    isActive: false, // Assuming inactive is a boolean
    ratings: 0, // Initialize ratings as a number
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openRatingsform, setOpenRatingsform] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const token = sessionStorage.getItem("token");

  const handleStatusClick = (status) => {
    const params = {
      isActive: status,
    };
    const response = axios.put(`${API_URL}ratingActive/${selectedId}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    handleCloseMenu();
    fetchData();
  };
  // Get method
  const fetchData = async () => {
    try {
      setRatings([]);
      const response = await axios.get(`${API_URL}ratings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data);
      setRatings(response.data.data);
      if (response.data.data.length > 0) {
        setTitle(response.data.data[0].title);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openBooleanvalues = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const editForm = (row) => {
    setEditData({
      decorId: row.decorId,
      comment: row.comment,
      isActive: row.isActive,
      ratings: row.ratings,
    });
    setSelectedId(row._id);
    setOpenRatingsform(true);
    handleCloseMenu();
  };

  const CloseRatingsform = () => {
    setOpenRatingsform(false);
  };

  const InputTextfieldchange = (event) => {
    const { name, value } = event.target;
    console.log("event target: " + event.target);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const EditRatings = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${API_URL}ratingActive/665eeb20938c388ab1c93c69${selectedId}`,
        editData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Ceremony updated successfully:", response.data);
      setRatingsdata(
        ratings.map((ratingdata) => (ratingdata._id === selectedId ? response.data : ratingdata))
      );
      setLoading(false);
      setOpenRatingsform(false);
    } catch (error) {
      console.error("Error updating ratingdata:", error);
      setLoading(false);
    }
    toast.success("Ceremony Edited successfully!");
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Delete method
  const handleDeleteratings = async (e, id) => {
    e.stopPropagation(); // Stop event propagation if needed
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${API_URL}rating/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Rating deleted successfully.");
        setRatings(ratings.filter((ratingdata) => ratingdata._id !== id));
        setLoading(false);
        toast.success("Rating deleted successfully!");
      } catch (error) {
        console.error("Error deleting rating:", error);
        toast.error("Failed to delete rating. Please try again."); // Show error toast if deletion fails
        setLoading(false);
        handleformatDate;
      }
    }
  };

  useEffect(() => {
    console.log("ratings", ratings);
  }, [ratings]);

  // Sample row data
  //formate date
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell style={{ fontWeight: "bold" }}>Decor Item</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Comment</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Is active</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Ratings</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {console.log("hfchggv ratings", ratings)}
          {ratings?.map((row) => (
            <TableRow key={row._id}>
              <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.decorId?.title}
              </TableCell>
              <TableCell sx={{ width: 180, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.comment}
              </TableCell>
              <>
                <TableCell
                  sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                  onClick={(e) => openBooleanvalues(e, row._id)}
                >
                  {row.isActive ? "Active" : "Inactive"}
                </TableCell>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedId === row._id}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                        fontFamily: "Roboto",
                        fontWeight: "450",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => handleStatusClick(true)}>Active</MenuItem>
                  <MenuItem onClick={() => handleStatusClick(false)}>Inactive</MenuItem>
                </Menu>
              </>
              <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: 450 }}>
                <Rating
                  name="read-only-rating"
                  value={row.rating}
                  precision={0.5}
                  readOnly
                  icon={<StarIcon fontSize="inherit" style={{ flex: 1, borderRadius: "8px" }} />}
                  emptyIcon={
                    <StarIcon
                      fontSize="inherit"
                      style={{ flex: 1, borderRadius: "8px", color: "#ccc" }}
                    />
                  }
                />
              </TableCell>
              <TableCell sx={{ width: 180, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {handleformatDate(row.createdOn)}
              </TableCell>
              <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                <IconButton aria-label="action" onClick={(e) => handleDeleteratings(e, row._id)}>
                  <DeleteIcon sx={{ mr: 1 }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              colSpan={9}
              rowsPerPage={rowsPerPage}
              page={page}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
