import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Stack } from "@mui/material";
import PropTypes from "prop-types";
import showConfirmationDialog from "../../utils/sweetalert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { API_URL } from "../../utils/url";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Modal,
  CircularProgress,
} from "@mui/material";

import { useState, useEffect } from "react";
// import Tableslist from "../tables/data/authorsTableData";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "50%",
};

export default function customer_management() {
  const [rows, setRows] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({ name: "", email: "", phoneNumber: "" });
  const [open, setOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = sessionStorage.getItem("token");
  const [selectedId, setSelectedId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get method

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}customer/customers`, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace with your actual access token
        },
      });
      setCustomers(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setError("Error fetching data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(customers);
  }, []);
  if (loading) {
    return (
      <Paper style={{ padding: 20, textAlign: "center" }}>
        <CircularProgress />
      </Paper>
    );
  }
  // update method

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleEditopen = (row) => {
    setFormData({ id: row._id, name: row.name, email: row.email, phoneNumber: row.phoneNumber }); // Populate the form with the row data
    setModelOpen(true);
    handleCloseMenu();
  };

  const custumerDataCancel = () => {
    setModelOpen(false);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const custumerDataUpdate = async () => {
    const { id, name, email, phoneNumber } = formData;

    try {
      const response = await fetch(`${API_URL}customer/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name, email, phoneNumber }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response text:", errorText);
        throw new Error(`Failed to update customer: ${errorText}`);
      }

      const result = await response.json();
      toast.success("Customer updated successfully!");

      // Update the state with the new data
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) => (customer._id === id ? result : customer))
      );

      setModelOpen(false);
    } catch (error) {
      console.error("Error updating customer:", error);
      toast.error("Error updating customer: " + error.message);
    }
  };
  // delete method
  const handleDeleteopen = async (id) => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        // setLoading(true);
        await axios.delete(`${API_URL}customer/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Customer deleted successfully");
        setCustomers(customers.filter((customer) => customer._id !== id));
        // setLoading(false);
        toast.success("Customer deleted successfully!");
      } catch (error) {
        console.error("Error deleting customer:", error);
        // setLoading(false);
        toast.error("Failed to delete customer.");
      }
    } else {
      console.log("Deletion canceled.");
    }
  };

  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };
  //
  const handleExportClick = () => {
    const data = customers.map((row) => ({
      Name: row.name,
      Phoneumber: row.phoneNumber,
      Email: row.email,
      CreatedOn: handleformatDate(row.createdOn),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "customers.xlsx");
  };
  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          style={{ paddingRight: "11%" }}
        ></Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "aliceblue", // Default text color
                  background: "blue", // Default background color
                  "&:hover": {
                    background: "blue", // Keep the background color the same on hover
                    color: "aliceblue", // Keep the text color the same on hover
                  },
                }}
                onClick={handleExportClick}
              >
                Export
              </Button>
            </Box>
          </div>
        </LocalizationProvider>
        <div style={{ marginTop: 40 }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
              aria-label="custom pagination table"
            >
              <TableRow style={{ background: "#49a3f1", height: "30%" }}>
                <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Phone Number</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>created On</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
              <TableBody>
                {customers && customers.length > 0 ? (
                  customers.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.phoneNumber}
                      </TableCell>
                      <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.email}
                      </TableCell>
                      <TableCell sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {handleformatDate(row.createdOn)}
                      </TableCell>
                      <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <IconButton aria-label="action" onClick={(e) => handleClick(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedRow === row}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: "center", horizontal: "right" }}
                          transformOrigin={{ vertical: "center", horizontal: "left" }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                                fontFamily: "Roboto",
                                fontWeight: "450",
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleEditopen(row)}>
                            <EditIcon sx={{ mr: 1 }} />
                            Edit
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={() => handleDeleteopen(row._id)}
                          >
                            <DeleteIcon sx={{ mr: 1 }} />
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                    colSpan={6}
                    // count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
                <Modal open={modelOpen} onClose={custumerDataCancel}>
                  <Box sx={style}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Name"
                      variant="standard"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      sx={{ flexDirection: "column", gap: 2, width: 230 }}
                      style={{ margin: 25 }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Email"
                      variant="standard"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Phone Number"
                      variant="standard"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      sx={{
                        marginLeft: 3,
                        marginTop: 1,
                        flexDirection: "column",
                        gap: 2,
                        width: 230,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        mt: 6,
                        colSpan: 4,
                        marginRight: 4,
                      }}
                    >
                      <Button
                        style={{
                          textTransform: "capitalize",
                          color: "white",
                          backgroundColor: "black",
                        }}
                        onClick={custumerDataUpdate}
                      >
                        Update
                      </Button>
                      <Button
                        style={{
                          textTransform: "capitalize",
                          fontSize: 15,
                          color: "black",
                          backgroundColor: "white",
                        }}
                        variant="outlined"
                        onClick={custumerDataCancel}
                        sx={{ ml: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Modal>
                <ToastContainer />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </DashboardLayout>
    </MDBox>
  );
}
