import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { TableCell } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import "./Decorsimage.scss";

// Ensure to set the root element for accessibility

const VideoTableCell = ({ videos, videoApiUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);

  const openLightbox = (index) => {
    setVideoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const videoUrls = videos.map((vid) => `${videoApiUrl}${vid}`);

  return (
    <TableCell>
      {videos.length > 0 && (
        <div className="video-grid">
          {videos.slice(0, 3).map((vid, index) => (
            <div
              key={index}
              className="video-thumbnail"
              onClick={() => openLightbox(index)}
              style={{
                backgroundImage: `url(${videoUrls[index]})`,
                cursor: "pointer",
              }}
            >
              <video
                src={videoUrls[index]}
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }}
                controls
              />
            </div>
          ))}
          {videos.length > 3 && (
            <div className="video-thumbnail remaining-videos" onClick={() => openLightbox(3)}>
              <span>{`+${videos.length - 3}`}</span>
            </div>
          )}
        </div>
      )}

      <Modal
        isOpen={isOpen}
        onRequestClose={closeLightbox}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            padding: 0,
            border: "none",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(2px)",
            zIndex: 400,
          },
        }}
      >
        <video
          src={videoUrls[videoIndex]}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          controls
        />
        {videos.length > 1 && (
          <>
            <ArrowBackIos
              onClick={() => setVideoIndex((videoIndex + videoUrls.length - 1) % videoUrls.length)}
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
                cursor: "pointer",
                fontSize: "2rem",
              }}
            />
            <ArrowForwardIos
              onClick={() => setVideoIndex((videoIndex + 1) % videoUrls.length)}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "white",
                cursor: "pointer",
                fontSize: "2rem",
              }}
            />
          </>
        )}
      </Modal>
    </TableCell>
  );
};

VideoTableCell.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.string).isRequired,
  videoApiUrl: PropTypes.string.isRequired,
};

export default VideoTableCell;
