import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Button, Stack, TextField, CardActions, CardContent, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import Notificationtable from "../../layouts/notification/Notificationtable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/url";
import { AsyncPaginate } from "react-select-async-paginate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import "../decors/Decorsimage.scss";

const formStyle = {
  position: "relative",
  top: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  marginBottom: 4,
};

function Categories() {
  const [formData, setFormData] = useState({
    title: "",
    decors: "",
    description: "",
    notes: "",
  });
  const [formOpen, setFormOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [message, setMessage] = useState("");
  const token = sessionStorage.getItem("token");
  const [button, setbutton] = useState(true);
  const [view, setview] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updatetable, setupdatetable] = useState(false);
  const [state, setState] = useState({
    Customer: false, // Switch is initially off
  });

  const switchChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  //

  const handleOpen = () => {
    setFormOpen(true);
    setbutton(false);
    setupdatetable(false);
  };

  const handleClose = () => {
    setFormOpen(false);
    setbutton(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    // Handle form save logic here
    console.log("Form data saved:", formData);
    handleClose();
  };

  // Dropdowntags
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (nameToDelete) => {
    setPersonName((names) => names.filter((name) => name !== nameToDelete));
  };

  //Dropdowndecors

  // Ceremonies Decors
  const [ceremoniesSelect, setCeremoniesSelect] = useState([]);
  const handleCeremoniesSelect = (event) => {
    setCeremoniesSelect(event.target.value);
  };

  const handleCeremoniesSelectDelete = (valueToRemove) => {
    setCeremoniesSelect((prevValues) => prevValues.filter((value) => value !== valueToRemove));
  };

  useEffect(() => {
    console.log(ceremoniesSelect);
  }, [ceremoniesSelect]);

  //Ceremonies tags
  const [ceremoniesClick, setCeremoniesClick] = useState([]);

  const handleCeremoniesClick = (event) => {
    setCeremoniesClick(event.target.value);
  };

  const handleCeremoniesClickDelete = (valueToRemove) => {
    setCeremoniesClick((prevValues) => prevValues.filter((value) => value !== valueToRemove));
  };

  useEffect(() => {
    console.log(ceremoniesClick);
  }, [ceremoniesClick]);
  // post method add form
  const [ceremonyimages, setCeremonyImages] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setCeremonyImages(file);
    }
  };
  const handleRemoveImage = () => {
    setSelectedFile(null);
  };

  // get method

  const asyncPaginateSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "2px",
      boxShadow: "none",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f2f2f2",
      borderRadius: "16px",
      padding: "2px 8px",
      margin: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#333",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#aaa",
      cursor: "pointer",
      ":hover": {
        color: "#333",
      },
    }),
    control: (base) => ({
      ...base,
      variant: "standard",
      border: "none",
      borderBottom: "1px solid #ccc",
      boxShadow: "none",
    }),
    container: (base) => ({
      ...base,
      marginBottom: "20px",
    }),
  };

  return (
    <div>
      <MDBox>
        <DashboardLayout>
          <DashboardNavbar />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            mb={5}
            gap={5}
            style={{ paddingLeft: "11%" }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div style={{ justifyContent: "flex-end", display: "flex" }}>
                <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "aliceblue", // Default text color
                      background: "blue", // Default background color
                      "&:hover": {
                        background: "blue", // Keep the background color the same on hover
                      },
                    }}
                    // onClick={handleExportClick}
                  >
                    Export
                  </Button>
                </Box>
              </div>
            </LocalizationProvider>
            {button && (
              <Button
                style={{ textTransform: "capitalize", fontSize: 15 }}
                variant="contained"
                color="dark"
                onClick={handleOpen}
              >
                + Add Form
              </Button>
            )}
          </Stack>

          {formOpen && (
            <Box sx={formStyle}>
              <Typography variant="h6" component="h2">
                Add Notification
              </Typography>
              <CardContent>
                <Row className="align-items-center" style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Col style={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Title"
                      variant="standard"
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ flexDirection: "column", width: 300 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Message"
                      variant="standard"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      sx={{ flexDirection: "column", gap: 2, width: "100%" }}
                      multiline
                      rows={2}
                    />
                  </Col>
                </Row>
                <Row style={{ display: "flex", gap: "20vh", marginTop: "6vh" }}>
                  <Col>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={state.Customer}
                              onChange={switchChange}
                              name="Customer"
                            />
                          }
                          label="All Customer"
                          labelPlacement="start"
                        />
                      </FormGroup>
                    </FormControl>
                  </Col>
                  {state.Customer && ( // Conditionally render the AsyncPaginate component based on the switch state
                    <Col style={{ width: "300px" }}>
                      <AsyncPaginate
                        isMulti
                        additional={{
                          page: 1,
                        }}
                        placeholder="Select Customer"
                        styles={asyncPaginateSelectStyles}
                        className="decor-asyncPaginate"
                      />
                    </Col>
                  )}
                </Row>
              </CardContent>
              <Row>
                <CardActions style={{ justifyContent: "start", marginLeft: "15px" }}>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "white",
                      backgroundColor: "black",
                    }}
                    variant="contained"
                    // onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      textTransform: "capitalize",
                      fontSize: 15,
                      color: "black",
                      backgroundColor: "white",
                    }}
                    variant="contained"
                    onClick={handleClose}
                    sx={{ ml: 2 }}
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Row>
            </Box>
          )}
          <div>
            <Notificationtable />
          </div>
        </DashboardLayout>
      </MDBox>
    </div>
  );
}

export default Categories;
