import { createSlice } from "@reduxjs/toolkit";

const teamMembersSlice = createSlice({
  name: "teamMembers",
  initialState: {
    usersPageData: [],
    teamMembersStartDate: "",
    teamMembersEndDate: "",
    filterData: null,
  },
  reducers: {
    setUsersPageData: (state, action) => {
      state.usersPageData = action.payload;
    },
    setTeamMembersStartDate: (state, action) => {
      state.teamMembersStartDate = action.payload;
    },
    setTeamMembersEndDate: (state, action) => {
      state.teamMembersEndDate = action.payload;
    },
    setfilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { setUsersPageData, setTeamMembersStartDate, setTeamMembersEndDate, setfilterData } =
  teamMembersSlice.actions;
export default teamMembersSlice.reducer;
