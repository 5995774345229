import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Avatar,
  Modal,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import showConfirmationDialog from "../../utils/sweetalert";
import { API_URL } from "../../utils/url";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "60%",
};

export default function CustomPaginationActionsTable() {
  const [address, setAddress] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    mail: "",
    pincode: "",
    state: "",
    label: "",
  });

  const [addressdata, setAddressdata] = useState([]);
  const [editData, setEditData] = useState({
    name: "",
    phone: "",
    mail: "",
    pincode: "",
    state: "",
    label: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openAddressform, setOpenAddressform] = useState(false);
  const [open, setOpen] = useState(false);
  const token = sessionStorage.getItem("token");

  //formate date
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  // Get method
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}adminAddresses`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data);
      setAddress(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadCeremonies = async () => {
    try {
      const response = await axios.get(`${API_URL}address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddressdata(response.data);
    } catch (error) {
      console.error("Error loading address:", error);
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openEditform = (row) => {
    setEditData({
      name: row.name,
      phone: row.phone,
      mail: row.mail,
      pincode: row.pincode,
      state: row.state,
      label: row.label,
    });
    setSelectedId(row._id);
    setOpenAddressform(true);
    handleCloseMenu();
  };

  const CloseAddressform = () => {
    setOpenAddressform(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("event target: " + event.target);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   edit method

  const EditAddress = async () => {
    try {
      setLoading(true);

      const response = await axios.put(`${API_URL}address/${selectedId}`, editData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Address updated successfully:", response.data);

      // Update local state with the updated address data
      setAddressdata(
        addressdata.map((address) => (address._id === selectedId ? response.data : address))
      );

      setLoading(false);
      setOpenAddressform(false); // Close the dialog or modal after successful update
      toast.success("Address edited successfully!");
    } catch (error) {
      console.error("Error updating address:", error); // Log detailed error message
      toast.error("Failed to update address. Please try again later.");
      setLoading(false);
    }
  };
  //   delete method

  const handleDeleteAddress = async () => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(`${API_URL}address/${selectedId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response data:", response.data);

        // Assuming 'address' is your state variable containing the addresses
        setAddress(address.filter((addr) => addr._id !== selectedId));

        // Notify user about successful deletion
        toast.success("Address deleted successfully!");
      } catch (error) {
        console.error("Error deleting address:", error);
        toast.error("Failed to delete address.");
      } finally {
        setLoading(false);
        handleCloseMenu();
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("address", address);
  }, [address]);
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>phoneNumber</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Mail</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Pincode</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>State</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {/* {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => ( */}
          {console.log("hfchggv address", address)}
          {address?.map((row) => (
            <TableRow key={row._id}>
              <TableCell sx={{ width: 20 }}></TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{ width: 120, fontFamily: '"Roboto"', fontWeight: "450" }}
              >
                {row.name}
              </TableCell>
              <TableCell sx={{ width: 160, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.phone}
              </TableCell>
              <TableCell sx={{ width: 220, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.mail}
              </TableCell>
              <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.pincode}
              </TableCell>
              <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.state}
              </TableCell>
              <TableCell sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {handleformatDate(row.createdOn)}
              </TableCell>
              <TableCell sx={{ width: 140, fontFamily: '"Roboto"', fontWeight: "450" }}>
                <IconButton aria-label="action" onClick={(e) => handleClick(e, row._id)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedId === row._id}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "center", horizontal: "right" }}
                  transformOrigin={{ vertical: "center", horizontal: "left" }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                        fontFamily: "Roboto",
                        fontWeight: "450",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => openEditform(row)}>
                    <EditIcon sx={{ mr: 1 }} />
                    Edit
                  </MenuItem>
                  <MenuItem sx={{ color: "error.main" }} onClick={handleDeleteAddress}>
                    <DeleteIcon sx={{ mr: 1 }} />
                    Delete
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              colSpan={9}
              //   count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Modal open={openAddressform} onClose={CloseAddressform}>
            <Box sx={style}>
              <TextField
                fullWidth
                margin="normal"
                label="Title"
                variant="standard"
                name="name"
                value={editData.name}
                onChange={handleInputChange}
                sx={{ flexDirection: "column", gap: 2, width: 230 }}
                style={{ margin: 25 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="PhoneNumber"
                variant="standard"
                name="phone"
                value={editData.phone}
                onChange={handleInputChange}
                sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="standard"
                name="mail"
                value={editData.mail}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Pincode"
                variant="standard"
                name="pincode"
                value={editData.pincode}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="State"
                variant="standard"
                name="state"
                value={editData.state}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Label"
                variant="standard"
                name="label"
                value={editData.label}
                onChange={handleInputChange}
                sx={{ marginLeft: 3, marginTop: 1, flexDirection: "column", gap: 2, width: 230 }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 8,
                  colSpan: 4,
                  marginRight: 4,
                }}
              >
                <Button
                  style={{ textTransform: "capitalize", color: "white", backgroundColor: "black" }}
                  onClick={EditAddress}
                >
                  Update
                </Button>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontSize: 15,
                    color: "black",
                    backgroundColor: "white",
                  }}
                  variant="outlined"
                  onClick={CloseAddressform}
                  sx={{ ml: 2 }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
