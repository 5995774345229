import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import showConfirmationDialog from "../../utils/sweetalert";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Avatar,
  Modal,
  CircularProgress,
} from "@mui/material";

import { useState, useEffect } from "react";
import { setfilterData, setUsersPageData } from "../../redux/slices/TeamMembersSlice";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange, disableNext } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={disableNext} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={disableNext} aria-label="last page">
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  disableNext: PropTypes.bool.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

export default function users({ updatetable }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  // const [usersPageData, setUsersPageData] = useState([]);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [selectedStatusRow, setSelectedStatusRow] = useState(null);
  const usersPageData = useSelector((state) => state.teamMembers.usersPageData);
  const startDateFormatted = useSelector((state) => state.teamMembers.teamMembersStartDate);
  const endDateFormatted = useSelector((state) => state.teamMembers.teamMembersEndDate);
  const [disableNext, setDisableNext] = useState(false);
  const [formData, setFormData] = useState({ name: "", mail: "", contact: "" });
  const [modelOpen, setModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [error, setError] = useState(null);
  const token = sessionStorage.getItem("token");
  const [editorview, setEditorView] = useState("");
  const [allPagesData, setAllPagesData] = useState([]);
  const [tabledata, setTableData] = useState("");

  // excel

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return ""; // Return an empty string or a default value if the timestamp is invalid
    }
    const date = new Date(Number(timestamp)); // Ensure timestamp is a number
    return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };
  // Get method
  const fetchUsers = async () => {
    const skip = page * rowsPerPage;
    const limit = rowsPerPage;

    if (allPagesData[page]) {
      dispatch(setUsersPageData(allPagesData[page]));
      setDisableNext(allPagesData[page].length < rowsPerPage);
      console.log("fetch users first if");
    } else {
      console.log("fetch users second if");
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}admin/users`, {
          params: { skip, limit, startDate: startDateFormatted, endDate: endDateFormatted },
          headers: { Authorization: `Bearer ${token}` },
        });

        const newPageData = response.data.data; // Ensure this matches your API structure
        setAllPagesData((prevData) => ({
          ...prevData,
          [page]: newPageData,
        }));
        dispatch(setUsersPageData(response.data.data));
        dispatch(setfilterData(response.data.exportData));
        setDisableNext(newPageData.length < rowsPerPage);
        setUsers(newPageData); // Ensure this updates the table data
        // const blob = new Blob([response.data], { type: "text/csv;charset=utf-8;" });
        // saveAs(blob, "FilteredData.csv");
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Error fetching data. Please try again later.");
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if ({ updatetable }) {
      fetchUsers();
    }
  }, [page, rowsPerPage, token, allPagesData, updatetable, startDateFormatted, endDateFormatted]);

  useEffect(() => {
    setAllPagesData([]);
    console.log("end date formatted use effect", endDateFormatted);
    if (endDateFormatted != "") {
      console.log("end date formatted after if", endDateFormatted);
      fetchUsers();
      console.log("end date formatted after fetch users function", endDateFormatted);
    }
  }, [updatetable, endDateFormatted]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  if (loading) {
    return (
      <Paper style={{ padding: 20, textAlign: "center" }}>
        <CircularProgress />
      </Paper>
    );
  }
  // update method

  const handleEditopen = (row) => {
    setFormData({ id: row._id, name: row.name, mail: row.mail, contact: row.contact });
    setModelOpen(true);
    handleCloseMenu();
  };

  const userDataCancel = () => {
    setModelOpen(false);
    setEditorView("");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const editIconClick = (userId) => {
    setSelectedUserId(userId);
    setEditModalOpen(true); // Assuming you have a modal to edit the profile image
  };
  //
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadProfileImage(file, formData._id);
    }
  };
  const handleStatusMenuClick = (event, row) => {
    setStatusAnchorEl(event.currentTarget);
    setSelectedStatusRow(row);
  };
  const handleCloseStatusMenu = () => {
    setStatusAnchorEl(null);
    setSelectedStatusRow(null);
  };

  const uploadProfileImage = (file) => {
    console.log("profileimage", file);
    const uploadData = new FormData();
    uploadData.append("profile", file);

    axios
      .post(`${API_URL}admin/users/profile`, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Profile image updated successfully:", response.data);
        const updatedProfileImage = response.data.data.profile;

        setFormData((prevData) => ({
          ...prevData,
          profile: updatedProfileImage,
        }));

        setTableData((prevTableData) =>
          prevTableData.map((row) =>
            row._id === formData._id ? { ...row, profile: updatedProfileImage } : row
          )
        );
      })
      .catch((error) => {
        console.error("Error uploading profile image:", error);
      });
  };
  //delete method
  const handleDeleteOpen = async (userId) => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();

    if (isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`${API_URL}admin/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Update the local state and Redux store to reflect the deletion
        const updatedUsersPageData = usersPageData.filter((user) => user._id !== userId);
        dispatch(setUsersPageData(updatedUsersPageData));
        setUsers(updatedUsersPageData); // Update the table data

        // Dynamically update the cached page data if you are caching pages
        setAllPagesData((prevData) => ({
          ...prevData,
          [page]: updatedUsersPageData,
        }));

        setLoading(false);
        toast.success("User deleted successfully!");
      } catch (error) {
        console.error("Error deleting user:", error);
        setLoading(false);
        toast.error("Failed to delete user. Please try again.");
      }
    } else {
      console.log("Deletion canceled.");
    }
  };

  const handleButtonClick = () => {
    setShowDatePickers((prev) => !prev);
  };
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  //Update
  const updateUser = async () => {
    const { id, name, mail, contact } = formData;

    try {
      const response = await fetch(`${API_URL}admin/users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name, mail, contact }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response text:", errorText);
        throw new Error(`Failed to update users: ${errorText}`);
      }

      const result = await response.json();
      toast.success("Customer updated successfully!");

      // Update the state with the new data without refreshing
      setUsers((prevUsers) =>
        prevUsers.map((user) => (user._id === id ? { ...user, ...result } : user))
      );

      // If you are using `allPagesData` for pagination, update that as well
      setAllPagesData((prevData) => ({
        ...prevData,
        [page]: prevData[page].map((user) => (user._id === id ? { ...user, ...result } : user)),
      }));

      setModelOpen(false);
    } catch (error) {
      console.error("Error updating users:", error);
      toast.error("Error updating users: " + error.message);
    }
  };

  //
  const handleStatusChange = async (userId, newRole) => {
    updateRole(userId, newRole);
    try {
      // Update the role using the API with Authorization header
      const response = await axios.put(
        `https://api.decors.digitaltriumph.co.in/api/admin/users/${userId}`,
        { role: newRole },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Ensure the Content-Type is set correctly
          },
        }
      );

      if (response.status === 200) {
        // Update the role in the local state (or notify parent component to update)
        updateRole(userId, newRole);
      }
    } catch (error) {
      console.error("Error updating role:", error);
    } finally {
      handleCloseStatusMenu();
    }
  };
  const updateRole = (userId, newRole) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user._id === userId ? { ...user, role: newRole } : user))
    );
  };

  return (
    <MDBox>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        style={{ paddingRight: "11%" }}
      ></Stack>
      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
            aria-label="custom pagination table"
          >
            <TableRow style={{ background: "#49a3f1", height: "30%" }}>
              <TableCell style={{ fontWeight: "bold" }}>Profile</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Mail</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Contact</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Role</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
            <TableBody>
              {usersPageData && usersPageData.length > 0 ? (
                usersPageData.map((row) => (
                  <TableRow key={row._id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        width: 220,
                        fontFamily: '"Roboto"',
                        fontWeight: "450",
                        position: "relative",
                      }}
                    >
                      <Avatar
                        src={row.profile ? `${IMAGEAPI_URL}${row.profile}` : undefined}
                        alt="Profile Picture"
                        sx={{ width: 50, height: 50 }}
                      >
                        {!row.profile && <PersonIcon />}
                      </Avatar>
                      <IconButton
                        onClick={() => editIconClick(row._id)}
                        sx={{
                          position: "absolute",
                          top: 0,
                          width: "20px",
                          height: "20px",
                          p: 0,
                          m: 0,
                        }}
                      ></IconButton>
                    </TableCell>
                    <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      {row.name}
                    </TableCell>
                    <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      {row.mail}
                    </TableCell>
                    <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      {row.contact}
                    </TableCell>
                    <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      <Button
                        style={{ padding: "0px" }}
                        onClick={(e) => handleStatusMenuClick(e, row)}
                      >
                        {row.role}
                      </Button>
                      <Menu
                        anchorEl={statusAnchorEl}
                        open={Boolean(statusAnchorEl) && selectedStatusRow === row}
                        onClose={handleCloseStatusMenu}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                      >
                        <MenuItem onClick={() => handleStatusChange(row._id, "Admin")}>
                          Admin
                        </MenuItem>
                        <MenuItem onClick={() => handleStatusChange(row._id, "Co-ordinator")}>
                          Co-ordinator
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            handleStatusChange(row._id, "Customer relationship manager")
                          }
                        >
                          CRM
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      {handleformatDate(row.createdOn)}
                    </TableCell>
                    <TableCell sx={{ width: 230, fontFamily: '"Roboto"', fontWeight: "450" }}>
                      <IconButton aria-label="action" onClick={(e) => handleMenuClick(e, row)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleCloseMenu}
                        anchorOrigin={{ vertical: "center", horizontal: "right" }}
                        transformOrigin={{ vertical: "center", horizontal: "left" }}
                        PaperProps={{
                          sx: {
                            p: 1,
                            width: 140,
                            "& .MuiMenuItem-root": {
                              px: 1,
                              typography: "body2",
                              borderRadius: 0.75,
                              fontFamily: "Roboto",
                              fontWeight: "450",
                            },
                          },
                        }}
                      >
                        <MenuItem onClick={() => handleEditopen(row, "View")}>
                          <VisibilityIcon sx={{ mr: 1 }} />
                          View
                        </MenuItem>
                        <MenuItem onClick={() => handleEditopen(row, "Edit")}>
                          <EditIcon sx={{ mr: 1 }} />
                          Edit
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "error.main" }}
                          onClick={() => handleDeleteOpen(row._id)}
                        >
                          <DeleteIcon sx={{ mr: 1 }} />
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 }}>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={7}
                  count={page * rowsPerPage + (disableNext ? usersPageData.length : rowsPerPage)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={(subprops) => (
                    <TablePaginationActions {...subprops} disableNext={disableNext} />
                  )}
                />
              </TableRow>
              <Modal open={modelOpen} onClose={userDataCancel}>
                <Box sx={style}>
                  {editorview === "View" && (
                    <IconButton
                      aria-label="close"
                      onClick={userDataCancel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  {editorview === "View" && (
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "15px",
                        width: 120,
                        height: 120,
                        backgroundColor: "#f0f0f0",
                        borderRadius: 2,
                      }}
                    >
                      <Avatar
                        src={formData.profile ? `${IMAGEAPI_URL}${formData.profile}` : undefined}
                        alt="Profile Picture"
                        sx={{ width: 100, height: 100, borderRadius: "50%" }}
                      >
                        {!formData.profile && <PersonIcon sx={{ width: 60, height: 60 }} />}
                      </Avatar>
                      <IconButton
                        onClick={() => document.getElementById("fileInput").click()}
                        sx={{
                          position: "absolute",
                          bottom: "92px",
                          right: "2px",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          p: 0.5,
                          boxShadow: 1,
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </Box>
                  )}

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Name"
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    sx={{ flexDirection: "column", gap: 2, width: 230 }}
                    style={{ margin: 25 }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Mail"
                    variant="standard"
                    name="mail"
                    value={formData.mail}
                    onChange={handleChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Contact"
                    variant="standard"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    sx={{
                      marginLeft: 3,
                      marginTop: 1,
                      flexDirection: "column",
                      gap: 2,
                      width: 230,
                    }}
                  />

                  {editorview === "View" && (
                    <>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Role"
                        variant="standard"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          marginLeft: 3,
                          marginTop: 1,
                          flexDirection: "column",
                          gap: 2,
                          width: 230,
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Created On"
                        variant="standard"
                        name="createdOn"
                        value={formatDate(formData.createdOn)}
                        onChange={handleChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          marginLeft: 3,
                          marginTop: 1,
                          flexDirection: "column",
                          gap: 2,
                          width: 230,
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Updated On"
                        variant="standard"
                        name="updatedOn"
                        value={formatDate(formData.updatedOn)}
                        onChange={handleChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{
                          style: { color: "black" },
                        }}
                        sx={{
                          marginLeft: 3,
                          marginTop: 1,
                          flexDirection: "column",
                          gap: 2,
                          width: 230,
                        }}
                      />
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      mt: 9,
                      colSpan: 4,
                      marginRight: 4,
                    }}
                  >
                    {editorview !== "View" && (
                      <>
                        <Button
                          style={{
                            textTransform: "capitalize",
                            color: "white",
                            backgroundColor: "black",
                          }}
                          onClick={updateUser}
                        >
                          Update
                        </Button>
                        <Button
                          style={{
                            textTransform: "capitalize",
                            fontSize: 15,
                            color: "black",
                            backgroundColor: "white",
                          }}
                          variant="outlined"
                          onClick={userDataCancel}
                          sx={{ ml: 2 }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </Modal>
              <ToastContainer />
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </MDBox>
  );
}
