import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import showConfirmationDialog from "../../utils/sweetalert";
import { API_URL, IMAGEAPI_URL } from "../../utils/url";
import DecorTableCell from "../../layouts/decors/Decorsimage";
import VideoTableCell from "../../layouts/decors/Decorsvideos";

import "./Decorsimage.scss";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

export default function Decorstable(updatetable) {
  const [decors, setDecors] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editorview, setEditorView] = useState("");
  const [enlargedImageSrc, setEnlargedImageSrc] = useState("");
  const [enlargedVideoSrc, setEnlargedVideoSrc] = useState(null);
  const [decorsdata, setdecorsdata] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    budget: "",
    ceremonies: "",
    images: "",
    videos: "",
    notes: "",
    decorItems: "",
    favorite: "",
    isCartAdded: "",
    ratings: "",
    decorRating: "",
    tags: "",
    createdOn: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openDecorsform, setOpenDecorsform] = useState(false);
  const [open, setOpen] = useState(false);
  const [videoopen, setVideoOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const token = sessionStorage.getItem("token");

  //   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };
  // Get method
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}decors`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data.data);
      setDecors(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadCeremonies = async () => {
    try {
      const response = await axios.get(`${API_URL}ceremonies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setdecorsdata(response.data);
    } catch (error) {
      console.error("Error loading decors:", error);
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const decorImageClick = (src) => {
    console.log("Image clicked:", src); // Debugging line
    setEnlargedImageSrc(src);
    setOpen(true);
  };

  const CloseDecorimage = () => {
    console.log("Modal closed"); // Debugging line
    setOpen(false);
    setEnlargedImageSrc("");
  };

  //image and video view

  const openMedia = (media, type) => {
    setCurrentMedia(media);
    setMediaType(type);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentMedia(null);
    setMediaType("");
  };

  const openEditform = (row, data) => {
    setEditorView(data);
    setEditData({
      title: row.title,
      description: row.description,
      budget: row.budget,
      ceremonies: row.ceremonies,
      notes: row.notes,
      images: row.images,
      videos: row.videos,
      decorItems: row.decorItems,
      favorite: row.favorite,
      isCartAdded: row.isCartAdded,
      ratings: row.ratings,
      decorRating: row.decorRating,
      tags: row.tags,
      createdOn: row.createdOn,
      updatedOn: row.updatedOn,
    });
    setSelectedId(row._id);
    setOpenDecorsform(true);
    handleCloseMenu();
  };

  const closeDecorsform = () => {
    setOpenDecorsform(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("event target: " + event.target);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //   edit method

  const editDecors = async () => {
    handleCloseMenu();
    try {
      const response = await axios.put(`${API_URL}decor/${selectedId}`, editData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Decor updated successfully:", response.data);

      // Update local state with the updated decors data

      setdecorsdata((prevdecors) =>
        prevdecors.map((decors) => (decors._id === selectedId ? response.data : decors))
      );

      setLoading(false);
      setOpenDecorsform(false);
      setEditorView(""); // Close the dialog or modal after successful update
      toast.success("Decor edited successfully!");
    } catch (error) {
      console.error("Error updating decors:", error); // Log detailed error message
      toast.error("Failed to update decors. Please try again later.");
      setLoading(false);
    }
  };

  //   delete method

  const handleDeleteDecors = async () => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        setLoading(true);
        const response = await axios.delete(`${API_URL}decor/${selectedId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response data:", response.data);

        // Assuming 'decors' is your state variable containing the decors
        setDecors(decors.filter((decor) => decor._id !== selectedId));

        // Notify user about successful deletion
        toast.success("Decor deleted successfully!");
      } catch (error) {
        console.error("Error deleting decor:", error);
        toast.error("Failed to delete decor.");
      } finally {
        setLoading(false);
        handleCloseMenu();
      }
    }
  };
  useEffect(() => {
    if (updatetable) {
      fetchData();
    }
  }, [updatetable]);

  useEffect(() => {
    console.log("decors", decors);
  }, [decors]);
  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return ""; // Return an empty string or a default value if the timestamp is invalid
    }
    const date = new Date(Number(timestamp)); // Ensure timestamp is a number
    return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
        aria-label="custom pagination table"
      >
        <TableRow style={{ background: "#49a3f1", height: "30%" }}>
          <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
          {/* <TableCell style={{ fontWeight: "bold" }}>Description</TableCell> */}
          <TableCell style={{ fontWeight: "bold" }}>Budget</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Videos</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>IsActive</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Ceremonies</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Images</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Decor Items</TableCell>
          {/* <TableCell style={{ fontWeight: "bold" }}>Notes</TableCell> */}
          <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
        </TableRow>
        <TableBody>
          {/* {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => ( */}
          {console.log("hfchggv decors", decors)}
          {decors?.map((row) => (
            <TableRow key={row._id}>
              <TableCell
                component="th"
                scope="row"
                sx={{ width: 170, fontFamily: '"Roboto"', fontWeight: "450" }}
              >
                {row.title}
              </TableCell>
              {/* <TableCell sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.description}
              </TableCell> */}
              <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.budget}
              </TableCell>
              <VideoTableCell videos={row.videos} videoApiUrl={IMAGEAPI_URL} />
              {enlargedVideoSrc && (
                <Box
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "60%",
                    height: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.3)",
                    zIndex: 1200,
                  }}
                  BackdropProps={{
                    style: { background: "rgba(0, 0, 0, 0.5)" },
                  }}
                  onClick={closeVideoLightbox}
                >
                  <video
                    src={enlargedVideoSrc}
                    style={{
                      maxWidth: "50%",
                      maxHeight: "50%",
                      objectFit: "contain",
                      cursor: "pointer",
                      borderRadius: "5px",
                      width: 135,
                    }}
                    controls
                    onClick={(e) => e.stopPropagation()}
                  />
                </Box>
              )}
              <TableCell sx={{ width: 100, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.isActive ? "True" : "False"}
              </TableCell>
              <TableCell sx={{ width: 160, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {/* {row.ceremonies.length > 0 ? row.ceremonies[0]._id : ""} */}
                {row.ceremonies && row.ceremonies.length > 0
                  ? row.ceremonies.map((ceremony) => ceremony.title).join(", ")
                  : ""}
              </TableCell>
              <DecorTableCell
                sx={{ width: 150, fontFamily: '"Roboto"', fontWeight: "450" }}
                images={row.images}
                imageApiUrl={IMAGEAPI_URL}
              />
              <TableCell sx={{ width: "35vh", fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.decorItems && row.decorItems.length > 0
                  ? row.decorItems
                      .map((item) => `${item.materialName}: ${item.quantity}`)
                      .join(", ")
                  : ""}
              </TableCell>
              {/* <TableCell sx={{ width: 160, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {row.notes}
              </TableCell> */}
              <TableCell sx={{ width: 120, fontFamily: '"Roboto"', fontWeight: "450" }}>
                {handleformatDate(row.createdOn)}
              </TableCell>
              <TableCell sx={{ width: 80, fontFamily: '"Roboto"', fontWeight: "450" }}>
                <IconButton aria-label="action" onClick={(e) => handleClick(e, row._id)}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedId === row._id}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "center", horizontal: "right" }}
                  transformOrigin={{ vertical: "center", horizontal: "left" }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      "& .MuiMenuItem-root": {
                        px: 1,
                        typography: "body2",
                        borderRadius: 0.75,
                        fontFamily: "Roboto",
                        fontWeight: "450",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={() => openEditform(row, "View")}>
                    <VisibilityIcon sx={{ mr: 1 }} />
                    View
                  </MenuItem>
                  <MenuItem onClick={() => openEditform(row, "Edit")}>
                    <EditIcon sx={{ mr: 1 }} />
                    Edit
                  </MenuItem>
                  <MenuItem sx={{ color: "error.main" }} onClick={handleDeleteDecors}>
                    <DeleteIcon sx={{ mr: 1 }} />
                    Delete
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
              colSpan={9}
              //   count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Modal open={openDecorsform} onClose={closeDecorsform}>
            <Box
              sx={{
                ...style,
                width: "45vw",
                height: "60vh",
                overflowY: "auto",
                position: "relative",
                borderRadius: 4, // Adjust the border radius here
                padding: 3,
              }}
            >
              {editorview === "View" && (
                <div>
                  <h5 style={{ marginLeft: "10px", justifyContent: "start", fontWeight: "bold" }}>
                    Services Details
                  </h5>
                  <IconButton
                    aria-label="close"
                    onClick={closeDecorsform}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <Box>
                <div style={{ display: "flex", gap: 10, width: "100%" }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Title"
                    variant="standard"
                    name="title"
                    value={editData.title}
                    onChange={handleInputChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{ flexDirection: "column", gap: 2 }}
                  />

                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    variant="standard"
                    name="description"
                    value={editData.description}
                    onChange={handleInputChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{ flexDirection: "column", gap: 2 }}
                  />
                </div>
                <div style={{ display: "flex", gap: 10, width: "100%" }}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Budget"
                    variant="standard"
                    name="budget"
                    value={editData.budget}
                    onChange={handleInputChange}
                    disabled={editorview === "View"}
                    InputLabelProps={{ style: { color: "black" } }}
                    sx={{ flexDirection: "column", gap: 2 }}
                  />
                  {editData.ceremonies &&
                    editData.ceremonies.map((ceremony, index) => (
                      <TextField
                        key={index}
                        fullWidth
                        margin="normal"
                        label={`Ceremonies ${index + 1}`}
                        variant="standard"
                        value={ceremony.title}
                        onChange={(event) => handleInputChange(index, event)}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                    ))}
                </div>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Notes"
                  variant="standard"
                  name="notes"
                  value={editData.notes}
                  onChange={handleInputChange}
                  disabled={editorview === "View"}
                  InputLabelProps={{ style: { color: "black" } }}
                  sx={{ flexDirection: "column", gap: 2 }}
                />
                {editorview === "View" && (
                  <>
                    <div style={{ display: "flex", gap: 10, width: "100%" }}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="DecorItems"
                        variant="standard"
                        name="decorItems"
                        value={
                          editData.decorItems && editData.decorItems.length > 0
                            ? editData.decorItems
                                .map((item) => `${item.materialName}: ${item.quantity}`)
                                .join(", ")
                            : "No decor items available"
                        }
                        onChange={handleInputChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Favorite"
                        variant="standard"
                        name="favorite"
                        value={editData.favorite}
                        onChange={handleInputChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="IsCartAdded"
                        variant="standard"
                        name="isCartAdded"
                        value={editData.isCartAdded}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        onChange={handleInputChange}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                    </div>
                    <div style={{ display: "flex", gap: 10, width: "100%" }}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Tags"
                        variant="standard"
                        name="tags"
                        value={editData.tags}
                        onChange={handleInputChange}
                        disabled={editorview === "View"}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="CreatedOn"
                        variant="standard"
                        name="createdOn"
                        value={formatDate(editData.createdOn)}
                        onChange={handleInputChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="UpdatedOn"
                        variant="standard"
                        name="updatedOn"
                        value={formatDate(editData.updatedOn)}
                        onChange={handleInputChange}
                        disabled={editorview === "View"}
                        InputLabelProps={{ style: { color: "black" } }}
                        sx={{ flexDirection: "column", gap: 2 }}
                      />
                    </div>
                    <Box>
                      <h6>Images</h6>
                      <Box
                        sx={{
                          display: "flex",
                          overflowX: "auto",
                          minWidth: "100%",
                          minHeight: "120px",
                          paddingBottom: 1,
                        }}
                      >
                        {editData.images &&
                          editData.images.length > 0 &&
                          editData.images.map((img, index) => (
                            <img
                              key={index}
                              src={`${IMAGEAPI_URL}/${img}`}
                              alt={`Decor Image ${index}`}
                              style={{
                                height: "100px",
                                display: "inline-block",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => openMedia(`${IMAGEAPI_URL}/${img}`, "image")}
                            />
                          ))}
                      </Box>
                      <h6>Videos</h6>
                      <Box
                        sx={{
                          display: "flex",
                          overflowX: "auto",
                          minWidth: "100%",
                          minHeight: "120px",
                          paddingBottom: 1,
                        }}
                      >
                        {editData.videos &&
                          editData.videos.length > 0 &&
                          editData.videos.map((vid, index) => (
                            <video
                              key={index}
                              src={`${IMAGEAPI_URL}/${vid}`}
                              controls
                              style={{
                                height: "100px",
                                display: "inline-block",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => openMedia(`${IMAGEAPI_URL}/${vid}`, "video")}
                            />
                          ))}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 8,
                  colSpan: 4,
                  marginRight: "13vh",
                }}
              >
                {editorview !== "View" && (
                  <>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        color: "white",
                        backgroundColor: "black",
                      }}
                      onClick={editDecors}
                    >
                      Update
                    </Button>
                    <Button
                      style={{
                        textTransform: "capitalize",
                        fontSize: 15,
                        color: "black",
                        backgroundColor: "white",
                      }}
                      variant="outlined"
                      onClick={closeDecorsform}
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
          <ToastContainer />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
