import React from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { Card, Stack } from "@mui/material";
import { Person } from "@mui/icons-material";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import showConfirmationDialog from "../../utils/sweetalert";

import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  MoreVert as MoreVertIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { API_URL } from "../../utils/url";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FilterListIcon from "@mui/icons-material/FilterList";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Button,
  Avatar,
  Modal,
  CircularProgress,
} from "@mui/material";

import { useState, useEffect } from "react";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  height: "50%",
};

export default function Tickets() {
  const [rows, setRows] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    decorName: "",
    customerId: "",
    bookingId: "",
    ticketStatus: "",
    query: "",
    description: "",
    _id: "",
  });
  const [open, setOpen] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const token = sessionStorage.getItem("token");
  const [selectedId, setSelectedId] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [selectedStatusRow, setSelectedStatusRow] = useState(null);
  const [editorview, setEditorView] = useState("");
  const [showDatePickers, setShowDatePickers] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusChange = async (ticketId, status) => {
    const params = {
      ticketStatus: status,
    };
    try {
      await axios.put(`${API_URL}ticket/${ticketId}`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchTickets(); // Refresh the tickets after updating the status
    } catch (error) {
      console.error("Error updating ticket status:", error);
      setError("Error updating status. Please try again later.");
    } finally {
      handleCloseMenu();
    }
  };
  // Get method

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}adminTickets`, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace with your actual access token
        },
      });
      setTickets(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching ticket data:", error);
      setError("Error fetching data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  if (loading) {
    return (
      <Paper style={{ padding: 20, textAlign: "center" }}>
        <CircularProgress />
      </Paper>
    );
  }
  // update method

  const handleEditopen = (row, data) => {
    setEditorView(data);
    setFormData({
      id: row._id,
      decorName: row.decorName,
      customerId: row.customerId,
      bookingId: row.bookingId,
      ticketStatus: row.ticketStatus,
      query: row.query,
      description: row.description,
      _id: row._id,
    }); // Populate the form with the row data
    setModelOpen(true);
    handleCloseMenu();
  };

  const ticketDataCancel = () => {
    setModelOpen(false);
    setEditorView("");
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleStatusMenuClick = (event, row) => {
    setStatusAnchorEl(event.currentTarget);
    setSelectedStatusRow(row);
  };

  const handleCloseStatusMenu = () => {
    setStatusAnchorEl(null);
    setSelectedStatusRow(null);
  };
  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const ticketDataUpdate = async () => {
    const { id, decorName, customerId, bookingId, ticketStatus, query } = formData;

    try {
      const response = await fetch(`${API_URL}ticket/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ decorName, customerId, bookingId, ticketStatus, query }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response text:", errorText);
        throw new Error(`Failed to update ticket: ${errorText}`);
      }

      const result = await response.json();
      toast.success("Ticket updated successfully!");

      // Update the state with the new ticket data
      setTickets((prevTickets) =>
        prevTickets.map((ticket) => (ticket._id === id ? result : ticket))
      );
      setModelOpen(false); // Close modal or reset form state
      setEditorView("");
    } catch (error) {
      console.error("Error updating ticket:", error);
      toast.error("Error updating ticket: " + error.message);
    }
  };
  // delete method
  const handleDeleteopen = async (id) => {
    handleCloseMenu();
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      try {
        await axios.delete(`${API_URL}ticket/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Ticket deleted successfully");
        setTickets(tickets.filter((ticket) => ticket._id !== id));
        toast.success("Ticket deleted successfully!");
      } catch (error) {
        console.error("Error deleting ticket:", error);
        toast.error("Failed to delete ticket.");
      }
    } else {
      console.log("Deletion canceled.");
    }
  };
  const handleformatDate = (timestamp) => {
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString(); // You can customize the format as needed
  };
  //
  const handleExportClick = () => {
    const data = tickets.map((row) => ({
      "Decor Name": row.decorName,
      Customer: row.customerId?.name,
      "Ticket Status": row.ticketStatus,
      Query: row.query,
      "Created On": handleformatDate(row.createdOn),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "tickets.xlsx");
  };

  return (
    <MDBox>
      <DashboardLayout>
        <DashboardNavbar />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          style={{ paddingRight: "11%" }}
        ></Stack>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "aliceblue", // Default text color
                  background: "blue", // Default background color
                  "&:hover": {
                    background: "blue", // Keep the background color the same on hover
                    color: "aliceblue", // Keep the text color the same on hover
                  },
                }}
                onClick={handleExportClick}
              >
                Export
              </Button>
            </Box>
          </div>
        </LocalizationProvider>
        <div style={{ marginTop: 40 }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
              aria-label="custom pagination table"
            >
              <TableRow style={{ background: "#49a3f1", height: "30%" }}>
                <TableCell style={{ fontWeight: "bold" }}>Decor Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Customer</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Ticket Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Query</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Created On</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
              <TableBody>
                {tickets &&
                  tickets.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: 220, fontFamily: '"Roboto"', fontWeight: "450" }}
                      >
                        {row.decorName}
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.customerId?.name}
                      </TableCell>
                      {/* <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.bookingId}
                      </TableCell> */}
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <Button onClick={(e) => handleStatusMenuClick(e, row)}>
                          {row.ticketStatus}
                        </Button>
                        <Menu
                          anchorEl={statusAnchorEl}
                          open={Boolean(statusAnchorEl) && selectedStatusRow === row}
                          onClose={handleCloseStatusMenu}
                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                          transformOrigin={{ vertical: "top", horizontal: "left" }}
                        >
                          <MenuItem onClick={() => handleStatusChange(row._id, "Open")}>
                            Open
                          </MenuItem>
                          <MenuItem onClick={() => handleStatusChange(row._id, "Closed")}>
                            Closed
                          </MenuItem>
                          <MenuItem onClick={() => handleStatusChange(row._id, "In Progress")}>
                            In Progress
                          </MenuItem>
                        </Menu>
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {row.query}
                      </TableCell>
                      <TableCell sx={{ width: 260, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        {handleformatDate(row.createdOn)}
                      </TableCell>
                      <TableCell sx={{ width: 230, fontFamily: '"Roboto"', fontWeight: "450" }}>
                        <IconButton aria-label="action" onClick={(e) => handleMenuClick(e, row)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedRow === row}
                          onClose={handleCloseMenu}
                          anchorOrigin={{ vertical: "center", horizontal: "right" }}
                          transformOrigin={{ vertical: "center", horizontal: "left" }}
                          PaperProps={{
                            sx: {
                              p: 1,
                              width: 140,
                              "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                                fontFamily: "Roboto",
                                fontWeight: "450",
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleEditopen(row, "View")}>
                            <VisibilityIcon sx={{ mr: 1 }} />
                            View
                          </MenuItem>
                          <MenuItem onClick={() => handleEditopen(row, "Edit")}>
                            <EditIcon sx={{ mr: 1 }} />
                            Edit
                          </MenuItem>
                          <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={() => handleDeleteopen(row._id)}
                          >
                            <DeleteIcon sx={{ mr: 1 }} />
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, { label: "All", value: -1 }]}
                    colSpan={6}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
                <Modal open={modelOpen} onClose={ticketDataCancel}>
                  <Box sx={style}>
                    {editorview === "View" && (
                      <IconButton
                        aria-label="close"
                        onClick={ticketDataCancel}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    <TextField
                      fullWidth
                      margin="normal"
                      label="DecorName"
                      variant="standard"
                      name="decorName"
                      value={formData.decorName}
                      onChange={handleChange}
                      disabled={editorview === "View"}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{ flexDirection: "column", gap: 2, width: 230 }}
                      style={{ margin: 25 }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Customer"
                      variant="standard"
                      name="customerId"
                      value={formData.customerId}
                      onChange={handleChange}
                      disabled={editorview === "View"}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{ marginTop: 3, flexDirection: "column", gap: 2, width: 280 }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Booking"
                      variant="standard"
                      name="bookingId"
                      value={formData.bookingId}
                      onChange={handleChange}
                      disabled={editorview === "View"}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        marginLeft: 3,
                        marginTop: 1,
                        flexDirection: "column",
                        gap: 2,
                        width: 230,
                      }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="TicketStatus"
                      variant="standard"
                      name="ticketStatus"
                      value={formData.ticketStatus}
                      onChange={handleChange}
                      disabled={editorview === "View"}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        marginLeft: 3,
                        marginTop: 1,
                        flexDirection: "column",
                        gap: 2,
                        width: 230,
                      }}
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Query"
                      variant="standard"
                      name="query"
                      value={formData.query}
                      onChange={handleChange}
                      disabled={editorview === "View"}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      sx={{
                        marginLeft: 3,
                        marginTop: 1,
                        flexDirection: "column",
                        gap: 2,
                        width: 230,
                      }}
                    />
                    {editorview === "View" && (
                      <>
                        <TextField
                          fullWidth
                          margin="normal"
                          label="Description"
                          variant="standard"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          disabled
                          InputLabelProps={{
                            style: { color: "black" },
                          }}
                          sx={{
                            marginLeft: 3,
                            marginTop: 1,
                            flexDirection: "column",
                            gap: 2,
                            width: 230,
                          }}
                        />
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        mt: 6,
                        colSpan: 4,
                        marginRight: 4,
                      }}
                    >
                      {editorview !== "View" && (
                        <>
                          <Button
                            style={{
                              textTransform: "capitalize",
                              color: "white",
                              backgroundColor: "black",
                            }}
                            onClick={ticketDataUpdate}
                          >
                            Update
                          </Button>
                          <Button
                            style={{
                              textTransform: "capitalize",
                              fontSize: 15,
                              color: "black",
                              backgroundColor: "white",
                            }}
                            variant="outlined"
                            onClick={ticketDataCancel}
                            sx={{ ml: 2 }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Modal>
                <ToastContainer />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </DashboardLayout>
    </MDBox>
  );
}
