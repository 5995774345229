import { createSlice } from "@reduxjs/toolkit";

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    ceremonies: [],
    categoriesStartDate: "",
    categoriesEndDate: "",
  },
  reducers: {
    setCeremonies: (state, action) => {
      state.ceremonies = action.payload;
    },
    setCategoriesStartDate: (state, action) => {
      state.categoriesStartDate = action.payload;
    },
    setCategoriesEndDate: (state, action) => {
      state.categoriesEndDate = action.payload;
    },
  },
});

export const { setCeremonies, setCategoriesStartDate, setCategoriesEndDate } =
  categoriesSlice.actions;
export default categoriesSlice.reducer;
